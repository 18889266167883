import React, { useEffect, useState } from 'react';
import './ManageUsers.css';
import { db } from '../firebase';
import { useUser } from 'reactfire';
import { fetchFromAPI, formatDate } from '../helpers';
import { LocalConvenienceStoreOutlined } from '@material-ui/icons';

function ManageUsers({users}) {
    const [userList, setUserList] = useState([]);
    const [userDetail, setUserDetail] = useState({});
    const [stripeSub, setStripeSub] = useState();
    const [loading, setLoading] = useState(false);
    const [searchInput,setSearchInput] = useState("");
    const [activeFilter, setActiveFilter] = useState(false);
    const [activePlanCount, setActivePlanCount] = useState(0);
    const user = useUser();

    console.log('user detail: ', userDetail);

    useEffect(() => {
        async function mergeUserListSubList(){
            let subList = await getAllSubscriptions();
            let mergedList = [];
            users?.forEach((user, userIndex) => {
                let newUser = user;
                let hasSub = false;
                subList?.forEach((subItem, subIndex) => {
                    if(subItem.customer === user.stripeCustomerId){
                        hasSub = subItem;
                    }
                });
                if(hasSub){
                    newUser.stripeSub = hasSub;
                }else{
                    newUser.stripeSub = {};
                }
                mergedList.push(newUser)
            });
            setUserList(mergedList);
        }
        if(userList?.length === 0){
            mergeUserListSubList();
        }
    }, [users])

    useEffect(()=>{console.log('USERLIST >>> ', userList)},[users, userList])

    const getAllSubscriptions = async () => {
        if (user) {
          const subs = await fetchFromAPI('admin/subscriptions', { method: 'GET' });
          await setStripeSub(subs);
          return subs;
        }
    };

    const addAdminPermission = async (uid) => {
        setLoading(true);
        await db.collection('users').doc(uid).set({settings:{isAdmin: 1}}, {merge: true});
        const firebaseUserList = await db.collection('users').get();
        if(firebaseUserList){
            setUserList(firebaseUserList.docs.map(doc => {
                const data = doc.data();
                const firebaseId = doc.id;
                return {firebaseId, ...data}
            }));
        }
        setUserDetail({...userDetail, settings: {...userDetail.settings, isAdmin: 1}});
        setLoading(false);
    }
    const removeAdminPermission = async (uid) => {
        setLoading(true);
        if(uid !== user.uid){
            await db.collection('users').doc(uid).set({settings:{isAdmin: 0}}, {merge: true});
            const firebaseUserList = await db.collection('users').get();
            if(firebaseUserList){
                setUserList(firebaseUserList.docs.map(doc => {
                    const data = doc.data();
                    const firebaseId = doc.id;
                    return {firebaseId, ...data}
                }));
            }
            setUserDetail({...userDetail, settings: {...userDetail.settings, isAdmin: 0}});
        }else{
            alert("You can't remove admin access from yourself");
        }
        setLoading(false);
    }

    const mergeUser = async (userList, subList) => {

    }

    const cancelUserSubscription = async (id) => {
        setLoading(true);
        await fetchFromAPI('subscriptions/' + id, { method: 'PATCH' });
        alert('Subscription Canceled');
        await getAllSubscriptions();
        setUserDetail({...userDetail, activePlan: {id: "", start: 0, end: 0}})
        setLoading(false);
    } 

    const handleSearchSubmit = async (e) => {
        e.preventDefault();
        let newUserList = [];
        users.forEach(user => {
            let regex = new RegExp(e.target.elements.search_input.value, 'gi');
            if(regex.test(user.email) || regex.test(user.firstName) || regex.test(user.lastName)){
                if(e.target.elements.active_only.checked && user.stripeSub.status === "active"){
                    newUserList.push(user);
                }else if(!e.target.elements.active_only.checked){
                    newUserList.push(user);
                }
                
            }
        });
        setUserList(newUserList);
    }

    return (
        <div className="manageUsers">
            <div className="manageUsers__title">
                <h1>Users</h1>
                <form onSubmit={(e)=>handleSearchSubmit(e)}>
                    <input type="text" placeholder="Search By Email, First Name or Last Name" name="search_input" className="search__input" value={searchInput} onChange={(e)=>setSearchInput(e.target.value)}/>
                    <div className="checkboxes">
                    <input type="checkbox" name="active_only" className="activeOnly__input" checked={activeFilter} onChange={(e)=>setActiveFilter(e.target.checked)} />
                    <label htmlFor="active_only">Active Only</label>
                    </div>
                    <button type="submit" className="search__button">Submit</button>
                </form>
            </div>
            
            <div className="manageUsers__list">
                <div className="manageUsers__header">
                    <div className="user__id">ID</div>
                    <div className="user__name">Name</div>
                    <div className="user__email">Email</div>
                    <div className="user__plans">Active Plans</div>
                    <div className="user__permissions">Permissions</div>
                    <div className="user__actions"></div>
                </div>
                {
                    userList?.map((user, userIndex) => {
                        return (
                            <div className="manageUsers__item" key={userIndex} onClick={() => setUserDetail(user)}>
                                <div className="user__id">{userIndex}</div>
                                <div className="user__name">{`${user.firstName} ${user.lastName}`}</div>
                                <div className="user__email">{user.email}</div>
                                <div className="user__plans">{user.stripeSub?.status === "active" ? "Yes" : "No"}</div>
                                <div className="user__permissions">
                                    {user.settings.isAdmin === 1 ? "Admin" : "User"}
                                </div>
                                <div className="user__actions">
                                    {
                                        
                                    }
                                    
                                </div>
                            </div>
                        )
                    })
                }
            </div>
            <div className="manageUsers__detailedView">
                {
                    userDetail?.email?.length > 0 && 
                    (
                        <>
                            <h2>User Detail</h2>
                            <div className="detailedView__container">
                                <div className="detailedView__left">
                                    <div className="property">
                                        <div className="title">
                                            Name:
                                        </div>
                                        <div className="value">
                                            {`${userDetail?.firstName} ${userDetail?.lastName}`}
                                        </div>
                                    </div>
                                    <div className="property">
                                        <div className="title">
                                            Email: 
                                        </div>
                                        <div className="value">
                                            {userDetail?.email}
                                        </div>
                                    </div>
                                    <div className="property">
                                        <div className="title">
                                            Firebase ID:
                                        </div>
                                        <div className="value">
                                            {userDetail?.firebaseId}
                                        </div>
                                    </div>
                                    <div className="property">
                                        <div className="title">
                                            Stripe ID:
                                        </div>
                                        <div className="value">
                                            {userDetail?.stripeCustomerId}
                                        </div>
                                    </div>
                                    {
                                        userDetail?.stripeSub.status &&
                                        (
                                            <>
                                                <div className="property">
                                                    <div className="title">
                                                        Active Plan Id:
                                                    </div>
                                                    <div className="value">
                                                        <ul>
                                                            <li>
                                                                {
                                                                    userDetail.stripeSub.status === "active" ? 
                                                                    userDetail.stripeSub.id : "No Active Plan"
                                                                }
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="property">
                                                    <div className="title">
                                                        Start Date:
                                                    </div>
                                                    <div className="value">
                                                        {
                                                            formatDate(userDetail?.stripeSub?.current_period_start, "MM/DD/YY")
                                                        }
                                                    </div>
                                                </div>
                                                <div className="property">
                                                    <div className="title">
                                                        End Date:
                                                    </div>
                                                    <div className="value">
                                                        {
                                                            formatDate(userDetail?.stripeSub?.current_period_end, "MM/DD/YY")
                                                        }
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    }
                                    
                                </div>
                                {
                                    !loading ?
                                    (
                                        <div className="detailedView__right">
                                            {
                                                (!loading && userDetail.settings.isAdmin === 0) &&
                                                (
                                                    <button 
                                                        onClick={() => addAdminPermission(userDetail.firebaseId)}
                                                        className="actions__assignAdmin"
                                                    >
                                                        Assign Admin
                                                    </button>
                                                )
                                            }
                                            {
                                                (!loading && userDetail.settings.isAdmin === 1) &&       
                                                (
                                                    <button 
                                                        onClick={() => removeAdminPermission(userDetail.firebaseId)}
                                                        className="actions__removeAdmin"
                                                    >
                                                        Remove Admin
                                                    </button>
                                                )
                                            }
                                            {
                                                userDetail?.activePlan.id.length > 0 &&
                                                (
                                                    <button 
                                                        onClick={()=>cancelUserSubscription(userDetail?.activePlan.id)}
                                                        className="actions__cancelSubscription"
                                                    >
                                                        Cancel Subscription
                                                    </button>
                                                )
                                            }
                                        </div>
                                    ):
                                    (
                                        <div className="detailedView__right">
                                            <div className="actions__buttonPlaceHolder">
                                                Working On it...
                                            </div>
                                            <div className="actions__buttonPlaceHolder">
                                                Working On it...
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                            
                        </>
                    )
                }
                
            </div>
        </div>
    )
}

export default ManageUsers
