export const staticTestSubList = [
    {
        id: "price_1I482rBI2czU4IFnVmQTiurS", 
        name: "Monthly",
        price: "$7.00",
        price_num: 700,
        freq: "month",
    },
    {
        id: "price_1I482rBI2czU4IFnzYkZ28zA",
        name: "Quarterly",
        price: "$20.00",
        price_num: 2000,
        freq: "quarter"
    },
    {
      id: "price_1I482rBI2czU4IFnWcN0blpe",
      name: "Bi-Yearly",
      price: "$40.00",
      price_num: 4000,
      freq: "6 Months"
    },
    {
      id: "price_1I4zjFBI2czU4IFnsbZwdMrE",
      name: "Yearly",
      price: "$79.00",
      price_num: 7900,
      freq: "Year"
    }
];

export const staticSubList = [
    {
        id: "price_1I1Z4dBI2czU4IFnQ6NuCPcW", 
        name: "Monthly",
        price: "$7.00",
        price_num: 700,
        freq: "month",
    },
    {
        id: "price_1I1Z6vBI2czU4IFn6jEIpyXn",
        name: "Quarterly",
        price: "$20.00",
        price_num: 2000,
        freq: "quarter"
    },
    {
      id: "price_1I1Z5cBI2czU4IFnXnrb0F6D",
      name: "Bi-Yearly",
      price: "$40.00",
      price_num: 4000,
      freq: "6 Months"
    },
    {
      id: "price_1IFjd9BI2czU4IFnvFezPiC5",
      name: "Yearly",
      price: "$79.00",
      price_num: 7900,
      freq: "Year"
    }
];

export const getSubList = () => {
  if (window.location.href.includes('localhost')) {
    return staticTestSubList;

  }

  return staticSubList;
}

export default staticSubList;