import React, {useState, useEffect} from 'react'
import './CountDownTimer.css';

function CountDownTimer({endDate}) {
    const calculateTimeLeft = (endDate) => {
        let timeleftObject = {};
        if(Date.now() < endDate){
            let timeleft = endDate - Date.now();
            timeleftObject = {
                days: Math.floor(timeleft / (1000*60*60*24)),
                hours: Math.floor((timeleft/(1000*60*60)) % 24),
                minutes: Math.floor((timeleft/1000/60) % 60),
                seconds: Math.floor((timeleft/1000) % 60)
            }
        }
        return timeleftObject;
    }

    
    const [ time, setTime ] = useState(calculateTimeLeft())

    useEffect(()=>{
        const timer = setTimeout(()=>{
            setTime(calculateTimeLeft(endDate))
        },1000);
        return () => clearTimeout(timer);
    },[time, endDate])

    let timeComponents = [];
    timeComponents = Object.keys(time).map(key => {
        return (
            <span key={`timeComponent__${key}_${time[key]}`}>
                <div className="timeComponent__value">{time[key]}</div>
                <div className="timeComponent__label">{time[key] === 1 ? key.substring(0,key.length-1) : key}</div>
            </span>
        )
    })

    return (
        <div className="countDownTimer">
            {
                timeComponents.length ? timeComponents : "Time's Up!"
            }
        </div>
    )
}

export default CountDownTimer
