import React from 'react'
import { Link } from 'react-router-dom';
import { SignIn } from './Customers';
import './NonUserHome.css';

function NonUserHome() {
    return (
        <div className="nonUserHome">
            <div className="nonUserHome__banner">
                <div className="banner__text">
                    <h1>Interested in My UPC Finder?</h1>
                    <p>Follow the steps to get set up with the most budget friendly Arbitrage Researching Tool you can find.</p>
                    <Link className="button" to="/steps">Follow These Steps</Link>
                    <center>or</center>
                    <SignIn />
                </div>
                <div className="banner__image">
                    <img src="./images/home_view.png" alt="My UPC Finder Screenshot Home" className="image__one"/>
                    <img src="./images/history_view.png" alt="My UPC Finder Screenshot History" className="image__two" />
                </div>
            </div>
        </div>
    )
}

export default NonUserHome
