import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { db } from '../firebase';
import { capitalize, formatDate } from '../helpers';

const FullAdminView = () => {

    const [ transactions, setTransactions ] = useState([]);
    const [ affiliateInfo, setAffiliateInfo ] = useState([]);
    const [ transactionMonthlySummary, setTransactionMonthlySummary ] = useState([]);
    const [ transactionSummary, setTransactionSummary ] = useState({});

    const getTransactions = async () => {
        const t = await db.collection('transactions').get();
        const aff = await db.collection('affiliates').get();
        if(t){
            setTransactions(t.docs.map(doc => {
                const data = doc.data();
                const transactionId = doc.id;
                return {transactionId, ...data}
            }))
        }
        if(aff){
            setAffiliateInfo(aff.docs.map(aff_doc => {
                const aff_data = aff_doc.data();
                const aff_id = aff_doc.id;
                return {aff_id, ...aff_data};
            }))
        }
    }

    useEffect(() => {
        // When component renders, get transactions
        getTransactions();
    }, [])


    useEffect(()=>{
        console.log('transactions >>>> ', transactions);
        console.log('affiliates >>> ',affiliateInfo);
        console.log('transaction monthly >>> ', transactionMonthlySummary);
        transactions.sort(function(a,b){return b.date-a.date})
        let summary = {
            totalDebitCount: 0,
            totalCreditCount: 0,
            totalCredits: 0,
            totalDebits: 0,
            totalAffDebitCount: 0,
            totalAffCreditCount: 0,
            totalAffCredits: 0,
            totalAffDebits: 0,
            totalPaidCommission: 0
        }

        let monthlySummary = {};

        if(transactions.length > 0){
            transactions.forEach(item=>{
                let aff_rate = 0;
                let aff_code = "no_affiliate";
                affiliateInfo?.forEach((aff) => {
                    if(aff.code === item.referral){
                        aff_rate = aff.fee_rate;
                        aff_code = item.referral;
                    }
                });
                //Tallying Overall Summary Numbers
                summary.totalCreditCount += item.entry === 'credit' ? 1 : 0;
                summary.totalDebitCount += item.entry === 'debit' ? 1 : 0;
                summary.totalCredits += item.entry === 'credit' ? item.amount : 0;
                summary.totalDebits += item.entry === 'debit' ? item.amount : 0;
                summary.totalAffCreditCount += (item.entry === 'credit' && aff_rate > 0) ? 1 : 0;
                summary.totalAffDebitCount += (item.entry === 'debit' && aff_rate > 0) ? 1 : 0;
                summary.totalAffCredits += item.entry === 'credit' ? item.amount*aff_rate : 0;
                summary.totalAffDebits += item.entry === 'debit' ? item.amount*aff_rate : 0;
                summary.totalPaidCommission += (item.entry === 'credit' && item.payment?.paid) ? item.payment.paid_amount : 0;

                //Tallying by Month and By Affiliate Summary Numbers
                if(!monthlySummary[item.year]){
                    monthlySummary[item.year] = {}
                }
                if(!monthlySummary[item.year][item.month]){
                    monthlySummary[item.year][item.month] = {};
                }
                if(!monthlySummary[item.year][item.month][aff_code]){
                    monthlySummary[item.year][item.month][aff_code] = {credits: 0, debits: 0, affCredits: 0, affDebits: 0};
                }
                monthlySummary[item.year][item.month][aff_code].credits += item.entry === 'credit' ? item.amount : 0;
                monthlySummary[item.year][item.month][aff_code].debits += item.entry === 'debit' ? item.amount : 0;
                monthlySummary[item.year][item.month][aff_code].affCredits += item.entry === 'credit' ? item.amount*aff_rate : 0;
                monthlySummary[item.year][item.month][aff_code].affDebits += item.entry === 'debit' ? item.amount*aff_rate : 0;
            });

            setTransactionSummary(summary);
            setTransactionMonthlySummary(monthlySummary);
        }
    }, [transactions])

    return (
        <div className="affiliateDash">
            <h1>Affiliate Dashboard</h1>
            <div className="affiliateDash__table">
                <h2>Transactions</h2>
                <div className="table__head">
                    <div className="table__header">Date</div>
                    <div className="table__header">D / C</div>
                    <div className="table__header">Amount</div>
                    <div className="table__header">Commission</div>
                    <div className="table__header">Referrer</div>
                    <div className="table__header">Transaction Type</div>
                    <div className="table__header">Pay Status</div>
                </div>
                <div className="table__body">
                    {
                        transactions.map((transaction, index) => {
                            let commission = 0.00;
                            let aff_rate = 0.00;
                            affiliateInfo.forEach(aff=>{
                                if(aff.code === transaction.referral){
                                    aff_rate = aff.fee_rate;
                                }
                            })
                            if(transaction.referral.length > 0){
                                commission = transaction?.payment?.amount_paid ? (transaction?.payment?.amount_paid/100).toFixed(2) : ((transaction.amount*aff_rate)/100).toFixed(2);
                            }else{
                                commission = commission.toFixed(2);
                            }
                            return(
                                <div className="table__row" key={transaction.transactionId}>
                                    <div className="table__data date">{formatDate(transaction.date,"MM/DD/YYYY")}</div>
                                    <div className="table__data entry" >{transaction.entry}</div>
                                    <div className="table__data amount">${(transaction.amount/100).toFixed(2)}</div>
                                    <div className="table__data commission">${commission}</div>
                                    <div className="table__data referral">{transaction.referral}</div>
                                    <div className="table__data type">{capitalize(transaction.type)}</div>
                                    <div className="table__data payStatus">
                                        {transaction?.payment?.paid ? "Paid" : "Unpaid"}
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            <div className="AffiliateDash__summary">
                <h2>Summary</h2>
                <div className="summary__container">
                    <div className="summary__card">
                        <h3>Total Transactions</h3>
                        <div className="summary__value">
                            {transactionSummary.totalCreditCount}
                        </div>
                    </div>
                    <div className="summary__card">
                        <h3>Total Commission</h3>
                        <div className="summary__value">
                            ${(((transactionSummary.totalAffCredits - transactionSummary.totalAffDebits))/100).toFixed(2)}
                        </div>
                    </div>
                    <div className="summary__card">
                        <h3>Total Paid</h3>
                        <div className="summary__value">
                            ${transactionSummary?.totalPaidCommission?.toFixed(2)}
                        </div>
                    </div>
                    <div className="summary__card">
                        <h3>Gross Sales</h3>
                        <div className="summary__value">
                            ${(transactionSummary.totalCredits/100).toFixed(2)}
                        </div>
                    </div>
                    <div className="summary__card">
                        <h3>Affiliate Rate</h3>
                        <div className="summary__value">
                            {(((transactionSummary.totalAffCredits - transactionSummary.totalAffDebits)).toFixed(2)/((transactionSummary.totalCredits - transactionSummary.totalDebits)).toFixed(2)*100).toFixed(2)}%
                        </div>
                    </div>
                </div>
            </div>
            <div className="AffiliateDash__summary">
                <h2>Monthly Summary</h2>
                <div className="summary__table">
                    <div className="summary__row">
                        <div className="row__year">Year</div>
                        <div className="row__month">Month</div>
                        <div className="row__affiliate">Affiliate</div>
                        <div className="row__grossSales">Gross Sales</div>
                        <div className="row__totalCommission">Total Commission</div>
                    </div>
                    {
                        Object.keys(transactionMonthlySummary).map(year => {
                            return Object.keys(transactionMonthlySummary[year]).map(month => {
                                return Object.keys(transactionMonthlySummary[year][month]).map(aff => {
                                    return(
                                        <div className="summary__row" key={`${year}-${month}-${aff}`}>
                                            <div className="row__year">{year}</div>
                                            <div className="row__month">{month}</div>
                                            <div className="row__affiliate">{aff}</div>
                                            <div className="row__grossSales">
                                                ${
                                                    ((transactionMonthlySummary[year][month][aff].credits - transactionMonthlySummary[year][month][aff].debits)/100).toFixed(2)
                                                }
                                            </div>
                                            <div className="row__totalCommission">
                                                ${
                                                    ((transactionMonthlySummary[year][month][aff].affCredits - transactionMonthlySummary[year][month][aff].affDebits)/100).toFixed(2)
                                                }
                                            </div>
                                        </div>
                                    )
                                })
                            })
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default FullAdminView;