import React, { useState } from 'react';
import styled from 'styled-components';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { CardElementStyle } from './CardElementStyle';
import { fetchFromAPI } from '../helpers';
import SubscriptionLicenseOptions from './SubscriptionLicenseOptions';
import { LICENSE_TYPE } from '../const';
import SubscribedModal from './SubscribedModal';

const AddLicenseContainer = styled.div`
    padding: 30px;
    box-sizing: border-box;
`;

const AddLicenseTitle = styled.h1`
    margin-bottom: 10px;
`;

const LicenseRequest = styled.div`
    margin-top: 40px;
`;

const LicenseForm = styled.form`
    position: relative; 
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    background: #ffffff;
    box-shadow: 0 4px 10px rgba(0,0,0,0.25);
    padding: 30px;
    box-sizing: border-box;
    min-height: 400px;
`;

const UserEmailInput = styled.input`
    width: 75%;
    margin: 30px 0;
    padding: 10px;
    box-sizing: border-box;
    font-size: 17px;
`;

const FormSubmitButton = styled.button`
    padding: 0.5em 2em;
    text-align: center;
    font-size: 17px;
    color: rgb(0,155,0);
    border: 1px solid rgb(0,155,0);
    background: #ffffff;
    margin: 30px auto;
    cursor: pointer;
    width: 100%;
    transition: all 200ms ease-in-out;

    &:hover {
        background: rgb(0,155,0);
        color: #ffffff;
    }
`;

const UserNicknameInput = styled.input`
    width: 75%;
    margin: 30px 0;
    padding: 10px;
    box-sizing: border-box;
    font-size: 17px;
`;

const NextButton = styled.button`
    position: absolute;
    bottom: 30px;
    right: 30px;
    padding: 0.5em 2em;
    color: rgb(0,155,0);
    background: #ffffff;
    border: 1px solid rgb(0,155,0);
    cursor: pointer;

    &:disabled{
        color: #999999;
        border-color: #999999;
        cursor: not-allowed;
    }
`;

const CheckoutRow = styled.div`
    display: flex;
    align-items: center;
    padding: 10px;
    margin: 5px auto;
    width: 100%;
`;

const CheckoutRowLabel = styled.div`
    flex-basis: 25%;
    margin-right: 0.5em;
    font-weight: 500;
    color: ${props => props.total ? 'rgb(0,155,0)' : '#000000'};
`;

const CheckoutRowValue = styled.div`
    color: ${props => props.total ? 'rgb(0,155,0)' : '#000000'};
`;

const ErrorMessage = styled.div`
    color: rgb(155,0,0);
    display: block;
    font-weight: 500;
`;

const LoadingContainer = styled.div``;

const FormPageHeading = styled.h2`
    margin-bottom: 30px;
`;

const FormButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
`;

const FormCancelButton = styled.button`
    width: 20%;
    flex-grow: 1;
    padding: 0.5em 2em;
    text-align: center;
    font-size: 17px;
    color: #555555;
    border: 0;
    background: #ffffff;
    margin: 30px auto;
    cursor: pointer;
    transition: all 200ms ease-in-out;

    &:hover{
        color: rgb(155,0,0);
    }
`;


const SubscriptionsAddLicense = ({ user }) => {
    const stripe = useStripe();
    const elements = useElements();
    const [ loading, setLoading ] = useState(false);
    const [ disableSubmit, setDisableSubmit ] = useState(true);
    const [ formPage, setFormPage ] = useState(1);
    const [ userEmail, setUserEmail ] = useState(null);
    const [ nickname, setNickname ] = useState(null);
    const [ product , setProduct ] = useState(null);
    const [ searching, setSearching ] = useState(false);
    const [ errorMessage, setErrorMessage ] = useState(null);
    const [ allowDiscount, setAllowDiscount ] = useState(false);
    const [ showSubscriptionModal, setShowSubscriptionModal ] = useState(false);
    const [ subInfo, setSubInfo ] = useState(null);

    const checkForUser = async (e) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@][^\s@]+$/i
        if (regex.test(e.target.value)) {
            setSearching(true);
            if (e.target.value !== user.email) {
                setErrorMessage(null);
                const emailExistsInSystem =  await fetchFromAPI(`users/check/${encodeURI(e.target.value)}`, { method: 'GET' });
                if (!emailExistsInSystem.message) {
                    // email does not currently have a stripe subscription
                    if (!emailExistsInSystem[0].stripeSubInfo) {
                        const licenseInfo = await fetchFromAPI('licenses', { method: 'GET'});
                        console.log('licenses >> ', licenseInfo);
                        // Grab additional license array for logged in user if available
                        if (licenseInfo) {
                            const hasLicense = licenseInfo.licenses.find(license => license.licenseUserFirebaseId === emailExistsInSystem[0].firebaseId);
                            if (hasLicense) {
                                // user already exists within additional licenses
                                setErrorMessage('This user already is part of your additional licenses');
                                setDisableSubmit(true);
                                setSearching(false);
                                setUserEmail(null);
                            } else {
                                // user does not exist within additional licenses.  Checks are complete
                                setErrorMessage(null);
                                setDisableSubmit(false);
                                setSearching(false);
                                setUserEmail(emailExistsInSystem[0]);
                                setAllowDiscount(licenseInfo.activePlan.end > Date.now() || licenseInfo.licenses.length > 0);
                            }
                        }
                    } else {
                        // email already has a current subscription
                        setErrorMessage('This user already has a current subscription');
                        setDisableSubmit(true);
                        setSearching(false);
                        setUserEmail(null);
                    }
                } else {
                    setUserEmail(null);
                    setDisableSubmit(true);
                    setSearching(false);
                    setErrorMessage('Email User Does Not Exist')
                }
            } else {
                setErrorMessage("You can't add an additional license for yourself");
            }
        } else {
            setUserEmail(false);
            setDisableSubmit(true);
            setSearching(false);
        }
    }

    const handleSubscriptionLicenseClick = (product) => {
        setProduct(product)
    }

    const handleSubConfirm = () => {
        setFormPage(1);
        setShowSubscriptionModal(false);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        const cardElement = elements.getElement(CardElement);
        // Create Payment Method
        const { paymentMethod, error } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
        });
        if (error) {
            alert(error.message);
            setLoading(false);
            return;
        }
        let couponCode = null;
        if (window.location.href.includes('localhost')) {
            if (allowDiscount) {
                couponCode = 'ZkuU2r1N';
            }
        } else {
            if (allowDiscount) {
                couponCode = 'AJfMnWVM';
            }
        }

        // Create License Subscription on the Server
        const license = await fetchFromAPI('licenses', {
            body: {
                plan: product.id,
                payment_method: paymentMethod.id,
                coupon: couponCode || '',
                license_info: {
                    firebaseId: userEmail.firebaseId,
                    nickname: nickname,
                }
            },
        });

        const { latest_invoice } = license;

        if (latest_invoice.payment_intent) {
            const { client_secret, status } = latest_invoice.payment_intent;

            if (status === 'requires_action') {
                const { error: confirmationError } = await stripe.confirmCardPayment(
                    client_secret
                );
                if (confirmationError) {
                    console.error(confirmationError);
                    alert('unable to confirm card');
                    return;
                }
            }

            // success
            setSubInfo(license); 
            setShowSubscriptionModal(true);
            console.log('license Info >> ', license);
        }

        setLoading(false);
    }

    // useEffect(() => {
    //     console.log('userEmail >>> ', userEmail);
    // }, [userEmail])

    return (
        <AddLicenseContainer>
            <AddLicenseTitle>Add Licenses</AddLicenseTitle>
            <p>
                This is where you can purchase additional licenses for other users. Each additional license purchased outside of the first license has a 5% discount attached to it. 
                As Stripe handles the entire transaction, no one else will be able to see your payment information and only you will be able to cancel this subscription.
            </p>
            <LicenseRequest>
                <LicenseForm onSubmit={handleSubmit}>
                    { formPage === 1 && (
                        <>
                            <FormPageHeading>What is the User's Email Address You Wish to Add a License For?</FormPageHeading>
                            <UserEmailInput type="email" onBlur={(e) => checkForUser(e)} placeholder="User's Email Address"/>
                            <NextButton disabled={!userEmail} onClick={() => setFormPage(2)}>{ searching ? 'Searching...' : 'Next'}</NextButton>
                        </>
                    )}
                    {
                        formPage === 2 && (
                            <>
                                <FormPageHeading>What would you like to name this account?</FormPageHeading>
                                <UserNicknameInput type="text" onChange={(e) => setNickname(e.target.value)} placeholder="Name This Account" />
                                <NextButton disabled={!nickname || nickname.length === 0} onClick={() => setFormPage(3)}>Next</NextButton>
                            </>
                        )
                    }
                    {
                        formPage === 3 && (
                            <>
                                <FormPageHeading>Choose Your Subscription Interval</FormPageHeading>
                                <SubscriptionLicenseOptions licenseType={LICENSE_TYPE.ADDITIONAL} onClick={handleSubscriptionLicenseClick} />
                                <NextButton onClick={() => setFormPage(4)}>Next</NextButton>
                            </>
                        )
                    }
                    {
                        formPage === 4 && (
                            <>
                                <FormPageHeading>Checkout Preview</FormPageHeading>
                                <CheckoutRow>
                                    <CheckoutRowLabel>License For: </CheckoutRowLabel>
                                    <CheckoutRowValue>{ userEmail.email } </CheckoutRowValue>
                                </CheckoutRow>
                                <CheckoutRow>
                                    <CheckoutRowLabel>Account Nickname: </CheckoutRowLabel>
                                    <CheckoutRowValue>{ nickname }</CheckoutRowValue>
                                </CheckoutRow>
                                <CheckoutRow>
                                    <CheckoutRowLabel>License Cost: </CheckoutRowLabel>
                                    <CheckoutRowValue> ${ (product.unit_amount/100).toFixed(2) } </CheckoutRowValue>
                                </CheckoutRow>
                                <CheckoutRow>
                                    <CheckoutRowLabel>License Discount: </CheckoutRowLabel>
                                    <CheckoutRowValue>
                                        { allowDiscount ? `$${((product.unit_amount*0.05)/100).toFixed(2)}` : '$0.00'}
                                    </CheckoutRowValue>
                                </CheckoutRow>
                                <CheckoutRow>
                                    <CheckoutRowLabel total>License Total: </CheckoutRowLabel>
                                    <CheckoutRowValue total>
                                        ${
                                            ((product.unit_amount/100) - (allowDiscount ? (product.unit_amount*0.05)/100 : 0)).toFixed(2)
                                        }
                                    </CheckoutRowValue>
                                </CheckoutRow>
                                <NextButton onClick={() => setFormPage(5)}>Next</NextButton>
                            </>
                        )
                    }
                    {
                        formPage === 5 && (
                            <>
                                <div className="stripeCardElement__container">
                                    <CardElement options={CardElementStyle} />
                                </div>
                                {
                                    !loading && (
                                        <FormButtonContainer>
                                            <FormSubmitButton disabled={disableSubmit} type="submit">{disableSubmit ? `Can't Submit` : 'Submit' }</FormSubmitButton>
                                            <FormCancelButton type="clear" onClick={() => setFormPage(1)}>Cancel</FormCancelButton>
                                        </FormButtonContainer>
                                    )
                                }
                                {
                                    loading && <LoadingContainer>Please Wait...</LoadingContainer>
                                }
                            </>
                        )
                    }
                    <ErrorMessage>
                        { errorMessage }
                    </ErrorMessage>
                </LicenseForm>
            </LicenseRequest>
            {
                (showSubscriptionModal && subInfo) && ( 
                    <SubscribedModal 
                        onConfirm={handleSubConfirm}
                        invoiceLink={subInfo ? subInfo.latest_invoice.invoice_pdf : null}
                    />
                )
            }
        </AddLicenseContainer>
    )
}

export default SubscriptionsAddLicense;