import React, { useEffect, useState } from 'react';
import './AdminDashboard.css';
import ManageUsers from './ManageUsers';
import GroupIcon from '@material-ui/icons/Group';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import DashboardIcon from '@material-ui/icons/Dashboard';
import { db } from '../firebase';
import Dashboard from './Dashboard';
import { fetchFromAPI } from '../helpers';
import ManageAffiliates from './ManageAffiliates';

function AdminDashboard() {
    const [view, setView] = useState("");
    const [users, setUsers] = useState([]);
    const [affiliates, setAffiliates] = useState([]);

    useEffect(() => {
        if(view === "manage_users"){
            async function getUsers(){
                const u = await db.collection('users').get();
                if(u){
                    console.log('u: ', u);
                    setUsers(u.docs.map(doc => {
                        const data = doc.data();
                        const firebaseId = doc.id;
                        return {firebaseId, ...data}
                    }));
                }
            }
            getUsers();
        }else if(view === "manage_aff"){
            async function getAffiliates(){
                const a = await db.collection('affiliates').get();
                if(a){
                    setAffiliates(a.docs.map(doc => {
                        const data = doc.data();
                        const firebaseId = doc.id;
                        return {firebaseId, ...data};
                    }));
                }
            }
            getAffiliates();

            async function getSubscriptionTransactions(){
                const result = await fetchFromAPI('transactions', { method: 'GET' });
                console.log('stripe result prior to check: ', result);
                if(result){
                    console.log('stripe result: ', result);
                }
            }

            getSubscriptionTransactions();
        }
    }, [view])

    return (
        <div className="adminDash">
            <div className="adminDash__menu">
                <ul>
                    <li onClick={()=>setView("dashboard")} className={view === 'dashboard' ? "selected" : ""}>
                        <DashboardIcon />
                        <h2>Statistics</h2>
                    </li>
                    <li
                        onClick={() => setView("manage_users")} 
                        className={view==="manage_users" ? "selected" : ""}
                    >
                        <GroupIcon />
                        <h2>Manage Users</h2>
                    </li>
                    <li
                        onClick={() => setView("manage_aff")} 
                        className={view==="manage_aff" ? "selected" : ""}
                    >
                        <AttachMoneyIcon />
                        <h2>Manage Affiliates</h2>
                    </li>
                </ul>
            </div>
            <div className="adminDash__view">
                {
                    view === "dashboard" &&
                    (
                        <Dashboard users={users} />
                    )
                }
                {
                    view === "manage_users" &&
                    (
                        <ManageUsers users={users}/>
                    )
                }
                {
                    view === "manage_aff" &&
                    (
                        <ManageAffiliates affiliates={affiliates} />
                    )
                }
            </div>
        </div>
    )
}

export default AdminDashboard
