import React, {useState} from 'react'
import './AffiliateSignUp.css';
import { db } from '../firebase';
import { fetchFromAPI } from '../helpers';

function AffiliateSignUp({user}) {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [company, setCompany] = useState("");
    const [paypal, setPaypal] = useState("");
    const [outreach, setOutreach] = useState(0);
    const [submit, setSubmit] = useState(false);
    const [alert, setAlert] = useState(false);
    const [displayThankYou, setDisplayThankYou] = useState(false);

    const handleSubmit = async (event) =>{
        event.preventDefault();
        setSubmit(true);
        let submitObject = {
            uid: user.uid,
            name: name.length > 0 ? name : false,
            email: email.length > 0 ? email : false,
            company: company.length > 0 ? company : false,
            paypal: paypal.length > 0 ? paypal : false,
            outreach: outreach,
        }
        let incomplete = false;
        Object.keys(submitObject).forEach(key => {
            if(!submitObject[key]){
                incomplete = true;
            }
        });

        if(!incomplete){
            const firebaseData = await db.collection('affiliates').where('paypal_id','==',submitObject.paypal).get();
            if(firebaseData.docs.length > 0){
                setAlert('You already have an account with that paypal ID.');
            }else{
                const affiliateSubmission = await fetchFromAPI('affiliates/signup', {
                    body: {
                      submitObject
                    },
                  });

                if(affiliateSubmission){
                    setDisplayThankYou(true);
                }
            }
            
        }else{
            setAlert('Please make sure to fill out all fields on the form.');
        }

        console.log('submit aff object >>> ', submitObject);
    }

    const closeAlert = (e) => {
        setAlert(false);
        setSubmit(false);
    }

    return !displayThankYou ? (
        <div className="AffiliateSignUp">
            <h1>Sign Up to be an Affiliate!</h1>
            <p>Want to be a My UPC Finder Affiliate Marketer?  Fill out the form below to begin the process of applying.</p>
            <div className="AffiliateSignUp__form">
                <div className="form__row">
                    <input type='text' placeholder="Name (required)" value={name} onChange={(e)=>setName(e.target.value)} />
                    <input type='text' placeholder="Email (required)" value={email} onChange={(e)=> setEmail(e.target.value)}/>
                </div>
                <div className="form__row">
                    <input 
                        type='text' 
                        placeholder="Company Name (required)" 
                        value={company} 
                        onChange={(e)=>setCompany(e.target.value)} 
                    />
                    <input 
                        type='text' 
                        placeholder="Paypal ID (required)" 
                        value={paypal} 
                        onChange={(e)=>setPaypal(e.target.value)}
                    />
                </div>
                <div className="form__row column">
                    <p>One Last Question...what is the estimated number of people you plan to outreach?</p>
                    <input 
                        type="number" 
                        min="0" 
                        step="25" 
                        placeholder="Estimated User Outreach" 
                        defaultValue={1}
                        value={outreach}
                        onChange={(e)=>setOutreach(e.target.value)}
                    />
                </div>
                <button disabled={submit} className={submit ? "disable" : ""} onClick={(e)=>handleSubmit(e)}>Submit</button>
            </div>
            {
                alert && (
                    <div className="AffiliateSignUp__alert">
                        <p>{alert}</p>
                        <button onClick={(e)=>closeAlert()}>OK</button>
                    </div>
                )
            }
            
        </div>
    ) : (
        <div className="AffiliateSignUp__thankYou">
            <h2>Thank You for Signing Up!</h2>
            <p>
                We have received your submission. We will work as quickly as possible to review your application and make a determination on the status of your request.  If you have any additional questions at all, please contact us at <a href="affiliates@712int.com">affiliates@712int.com</a>
            </p>
        </div>
    )
}

export default AffiliateSignUp
