import React, { useState, useEffect } from 'react'
import { fetchFromAPI } from '../helpers';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { useUser, AuthCheck, SuspenseWithPerf } from 'reactfire';
import firebase from 'firebase/app';
import { auth, db } from '../firebase';
import './Customers.css';
import { useHistory } from 'react-router-dom';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

export function SignIn(){
    const user = useUser();
    const signIn = async () => {
        const credential = await auth.signInWithPopup(
            new firebase.auth.GoogleAuthProvider()
        );
        console.log('user credentials: ', credential);
        const {uid, email} = credential.user;
        await db.collection('users').doc(uid).get().then(snapshot => {
            if(!snapshot.exists){
                const fillUser = initializeNewUser(email);
                db.collection('users').doc(uid).set(fillUser["fill"], {merge: true});
            }
        });
    };
    return(
        <button onClick={signIn} className="buttons__signin">
            Sign In With <i className="fab fa-google"></i>
        </button>
    );
}

export function initializeNewUser(email){
    let newUser = {};
    const fill = {
        activePlan: {
            id: "",
            start: 0,
            end: 0
        },
        created: new Date().getTime(),
        firstName: "",
        lastName: "",
        email,
        referId: "",
        settings: {
            isAdmin: 0,
            isAffiliate: 0,
            affiliateId: "",
            isDarkMode: 0,
        },
        stripeCustomerId: ""
    };
    newUser["fill"] = fill;
    console.log('new user info: ', newUser);
    return newUser;
}

export function SignOut({user, useIcon = false, title = null}){
    let history = useHistory();
    const handleSignOut = async () => {
        await auth.signOut();
        history.push("/");
    }

    return (
        <>
            { (user && !useIcon) && 
                (
                    <button onClick={() => handleSignOut()} className="buttons__signout" title={title}>
                        Sign Out
                    </button>
                )
            }
            
            {
                (user && useIcon) && (
                    <ExitToAppIcon onClick={() => handleSignOut()} title={title} />
                )
            }       
        </>
    )
    
}

function SaveCard(props){
    const stripe = useStripe();
    const elements = useElements();
    const user = useUser();

    const [setupIntent, setSetupIntent] = useState();
    const [wallet, setWallet] = useState([]);

    useEffect(() => {
        getWallet();
    }, [user]);

    const createSetupIntent = async(event)=> {
        const si = await fetchFromAPI('wallet');
        setSetupIntent(si);
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        const cardElement = elements.getElement(CardElement);
        //Confirm Card Setup
        const {
            setupIntent: updatedSetupIntent,
            error,
        } = await stripe.confirmCardSetup(setupIntent.client_secret, {
            payment_method: {card: cardElement}
        });

        if(error){
            alert(error.message);
            console.log(error);
        }else{
           setSetupIntent(updatedSetupIntent);
           await getWallet();
           alert('Success! Card added to your wallet'); 
        }
    };

    const getWallet = async () => {
        console.log('user: ', user);
        if(user){
            const paymentMethods = await fetchFromAPI('wallet', {method: 'GET'});
            setWallet(paymentMethods);
            console.log('wallet: ', wallet);
        }
    }

    return(
        <>
            <AuthCheck fallback={<SignIn />}>
                <div>
                    <button 
                        onClick={createSetupIntent}>
                        Attach New Credit Card
                    </button>
                </div>
                <form onSubmit={handleSubmit}>
                    <CardElement />
                    <button type="submit">
                        Attach
                    </button>
                </form>
                <select>
                    <option>Select Saved Card</option>
                    {wallet.map((paymentSource) => (
                       <CreditCard key={paymentSource.id} card={paymentSource.card} /> 
                    ))}
                </select>
                <div>
                    <SignOut user={user} />
                </div>
            </AuthCheck>
        </>
    )
}

function CreditCard({last4, brand, exp_month, exp_year}){
    return(
        <option>
            {brand} **** **** **** {last4}  expires {exp_month}/{exp_year};
        </option>
    )
}

export default function Customers(){
    return(
        <SuspenseWithPerf fallback={<SignIn />}>
            <SaveCard />
        </SuspenseWithPerf>
    )
}
