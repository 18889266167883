import React, { useState, useEffect, Suspense } from 'react';
import { fetchFromAPI, capitalize, formatDate, determineFreq, getProductID } from '../helpers';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { useUser, AuthCheck } from 'reactfire';
import './Subscriptions.css';
import { db } from '../firebase';
import { CardElementStyle } from './CardElementStyle';
import { getSubList } from '../subscriptionIDs';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { LICENSE_TYPE } from '../const';
import SubscribedModal from './SubscribedModal';

const DiscountCode = styled.input`
  display: block;
  width: 95%;
  margin: 10px auto;
  padding: 5px;
  box-sizing: border-box;
  font-size: 17px;
  border: 1px solid rgb(0,155,0);
  border-radius: 2px;
`;

const SubListPlaceholder = styled.div`
  width: 200px;
  height: 150px;
  background: #999999;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.25);
  margin: 0 10px;
`;

const SubAddLicenses = styled.div`
  text-align: center;
  > a {
    display: block;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: rgb(0,155,0);
    transition: all 200ms ease-in-out;

    > svg {
      margin-right: 0.5em;
    }

    &:hover{
      color: #333333;
    }
  }
`;

const SubscriptionNickname = styled.div`
  color: rgb(0,155,0);
  font-size: 15px;
  display: block;
`;

const SubItemMessage = styled.div`
  color: ${props => props.isLicensedUser ? 'rgb(0,155,0)' : 'rgb(155,0,0)'};
`;

const SubItemDescription = styled.div`
  color: #333;
  font-weight: 500;
  font-size: 17px;
  margin-top: 15px;
`;

// Shows user document in Firestore
function UserData({user}) {

  const [data, setData] = useState({});
  const [loadingPortal, setLoadingPortal] = useState(false);
  const [userHasSubscription, setUserHasSubscription ] = useState(false);

  // Subscribe to the user's data in Firestore
  useEffect(
    () => {
      const unsubscribe = db.collection('users').doc(user.uid).onSnapshot(doc => setData(doc.data()));
      return () => unsubscribe()
    },
    [user]
  )

  useEffect(()=>{
    if(data?.activePlan?.start > 0 && data?.activePlan?.end > 0){
      setUserHasSubscription(true);
    }else{
      setUserHasSubscription(false);
    }
  },[data]);

  const goToStripePortal = async (stripeId) => {
    setLoadingPortal(true);
    const portal = await fetchFromAPI('customer-portal-session', {
      method: 'POST',
      body: {customerId: stripeId}
    });
    if(portal?.url){
      window.open(portal.url, "_blank")
    }
    console.log('portal url >>> ', portal);
    setLoadingPortal(false);
  }

  return (
    <div className="userPortal">
      <pre>
        Stripe Customer ID: {data?.stripeCustomerId} <br />
      </pre>
      {
        data?.stripeCustomerId && data?.stripeCustomerId?.length > 0 && (
          !loadingPortal ? 
          (
            <button className="userPortal__button" onClick={()=>goToStripePortal(data.stripeCustomerId)}>
              Stripe Portal
            </button>
          ):
          (
            <div className="loadingStripePortal">Getting Portal Link...</div>
          )
        )
      }
      
    </div>
    
  );
}

const SubscribeToPlan = ({ settings }) => {
  const stripe = useStripe();
  const elements = useElements();
  const user = useUser();
  const subList = getSubList();

  const [ plan, setPlan ] = useState();
  const [ subscriptions, setSubscriptions ] = useState([]);
  const [ loading, setLoading ] = useState(false);
  const [ friendlyReminder, setFriendlyReminder ] = useState(0);
  const [ coupon, setCoupon ] = useState('');
  const [ availableCoupons, setAvailableCoupons ] = useState([]);
  const [ disableButton, setDisableButton ] = useState(false);
  const [ hasPrimarySub, setHasPrimarySub ] = useState(false);
  const [ isLicensedUser, setIsLicensedUser ] = useState(false);
  const [ showSubscribedModal, setShowSubscribedModal ] = useState(false);

  // Get current subscriptions on mount
  useEffect(() => {
    getSubscriptions();
    getAvailableCoupons();
  }, [user]);

  useEffect(() => {
    if (loading) {
      setDisableButton(true);
    } else {
      setDisableButton(false);
    }
  }, [loading]);

  useEffect(() => {
    console.log('coupon set >>> ', coupon);
    console.log('plan >>> ', plan);
    console.log('licenseUser >>> ', isLicensedUser);
  }, [coupon, plan, isLicensedUser])

  // Fetch current subscriptions from the API
  const getSubscriptions = async () => {
    if (user) {
      setLoading(true);
      const subs = await fetchFromAPI('subscriptions', { method: 'GET' });
      await setSubscriptions(subs);
      if (subs.findIndex(sub => sub.plan.product === getProductID(LICENSE_TYPE.REGULAR)) > -1) {
        setHasPrimarySub(true);
      } else {
        setHasPrimarySub(false);
        if (settings && settings.activePlan && settings.activePlan.id.length > 0) {
          if (settings.activePlan.type) {
            settings.activePlan.type === 'additional_license' ? setIsLicensedUser(true) : setIsLicensedUser(false);
          } else {
            setIsLicensedUser(false);
          }
        }
      }
      console.log('subscriptions', subs);
      setLoading(false);
    }
  };

  const getAvailableCoupons = async () => {
    if (user) {
      const coupons = await fetchFromAPI('coupons/active', { method: 'GET' });
      await setAvailableCoupons(coupons);
    }
  }

  const checkClick = () => {
    if(hasPrimarySub || isLicensedUser){
      let alertContainerBG = document.createElement('div');
      alertContainerBG.classList.add('sub__alertBG');
      let alertContainer = document.createElement('div');
      alertContainer.classList.add('sub__alert');
      let closeButton = document.createElement('div');
      closeButton.classList.add('sub__alertClose');
      closeButton.innerText = "Ok, Got It!";
      let text = document.createElement('div');
      text.classList.add('sub__alertText');
      text.innerHTML = `<h2>Currently Subscribed</h2><p>In order to select a new subscription you must first cancel your current subscription.</p>`;
      alertContainer.appendChild(text);
      alertContainer.appendChild(closeButton);
      closeButton.addEventListener('click', e => {
        e.preventDefault();
        alertContainer.remove();
        alertContainerBG.remove();
      });
      alertContainerBG.addEventListener('click', e => {
        e.preventDefault();
        alertContainerBG.remove();
        alertContainer.remove();
      });
      let body = document.getElementsByTagName('body')[0];
      body.appendChild(alertContainerBG);
      body.appendChild(alertContainer);
    }else if(friendlyReminder === 0){
      setFriendlyReminder(1);
    }
  }

  // Cancel a subscription
  const cancel = async (id) => {
    console.log('clicked cancel button');
    setLoading(true);
    await fetchFromAPI('subscriptions/' + id, { method: 'PATCH' });
    alert('canceled!');
    await getSubscriptions();
    setLoading(false);
  };

  const checkForValidDiscount = (e) => {
    if (availableCoupons && availableCoupons.length > 0) {
      if (e.target.value.length === 0) {
        setCoupon(null);
        setDisableButton(false);
      } else {
        const isCoupon = availableCoupons.find(coupon => coupon.code === e.target.value);
        setCoupon(isCoupon)
        setDisableButton(!isCoupon);
      }
    } else {
      setCoupon(null);
      setDisableButton(true);
    }

  }

  // Handle the submission of card details
  const handleSubmit = async (event) => {
    setLoading(true);
    event.preventDefault();

    const cardElement = elements.getElement(CardElement);

    // Create Payment Method
    const { paymentMethod, error } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    if (error) {
      alert(error.message);
      setLoading(false);
      return;
    }

    const couponCode = (coupon && coupon.coupon) && coupon.coupon.id;

    // Create Subscription on the Server
    const subscription = await fetchFromAPI('subscriptions', {
      body: {
        plan,
        payment_method: paymentMethod.id,
        coupon: couponCode || '',
      },
    });

    // The subscription contains an invoice
    // If the invoice's payment succeeded then you're good, 
    // otherwise, the payment intent must be confirmed

    const { latest_invoice } = subscription;

    if (latest_invoice.payment_intent) {
      const { client_secret, status } = latest_invoice.payment_intent;

      if (status === 'requires_action') {
        const { error: confirmationError } = await stripe.confirmCardPayment(
          client_secret
        );
        if (confirmationError) {
          console.error(confirmationError);
          alert('unable to confirm card');
          return;
        }
      }

      // success
      setShowSubscribedModal(true);
      await getSubscriptions();
    }

    setLoading(false);
    setPlan(null);
  };

  const handleConfirmClick = () => {
    setLoading(true);
    setShowSubscribedModal(false);
    setLoading(false);
  }

  const calculateSubscriptionPayment = (sub) => {
    const hasDiscount = sub.discount;
    const amountOff = hasDiscount && sub.discount.coupon.amount_off;
    const percentOff = hasDiscount && sub.discount.coupon.percent_off/100;

    if (!hasDiscount) {
      return (sub.plan.amount/100).toFixed(2)
    } else {
      if (sub.discount.coupon.amount_off) {
        return ((sub.plan.amount - amountOff)/100).toFixed(2);
      } else if (percentOff) {
        return ((sub.plan.amount - (percentOff * sub.plan.amount))/100).toFixed(2)
      }
    }
  }

  return (
    <>
      <AuthCheck fallback={""}>
          {user?.uid && 
            (
                <div className="subUser">
                    <img src={user?.photoURL} alt={user.displayName} />
                    <h2>{user.displayName}</h2>
                    <UserData user={user} subList={subList}/>
                </div>
            )
          }
        <div className="subSignUp">
            <h2>Choose a Plan:</h2>
            <div className="subSignUp__buttonGroup">
                {
                  ( loading ) ? (
                    [1,2,3,4].map(item => <SubListPlaceholder /> )
                  ) : ( 
                  subList.map((listItem, listIndex) => (
                          <button 
                            onClick={() => {setPlan(listItem.id); checkClick();}} 
                            className={`
                              ${listItem.id === subscriptions[0]?.plan.id ? "selected" : ""}
                              ${listItem.id === plan ? " clicked" : ""}
                              `
                            }
                            key={`subscription_button_${listIndex}`}
                          >
                              <div className="buttonGroup__title">{listItem.name}</div> 
                              <div className="buttonGroup__desc">
                                <span className={`desc__price ${coupon && coupon.coupon.percent_off && 'strikethrough'}`}>{listItem.price}</span>
                                {
                                  ( coupon && coupon.coupon.percent_off ) && (
                                    <div className="discount_price">
                                      ${ (((100 - coupon.coupon.percent_off) * (listItem.price_num/100))/100).toFixed(2) }
                                    </div>
                                  )
                                }
                              </div>
                          </button>
                      )) 
                  )
                }
            </div>
        </div>
        { (plan && !hasPrimarySub && !isLicensedUser) &&
          <div className="stripeCardElement">
            <form onSubmit={handleSubmit} hidden={!plan}>
              <div className="stripeCardElement__container">
                <CardElement options={CardElementStyle}/>
              </div>
              <DiscountCode type="text" id="discount_code" name="discount_code" placeholder="Discount Code" onChange={(e) => checkForValidDiscount(e)}/>
              <button type="submit" disabled={disableButton} className="stripeCardElement__submit">
                Subscribe &amp; Pay
              </button>
            </form>
          </div>
        }
        <SubAddLicenses>
          <Link to="/subscriptions/licenses"><AddCircleOutlineIcon /> Add Licenses</Link>
        </SubAddLicenses>
        <div className="subManage">
          <h2>Manage Current Subscriptions</h2>
          <div className="subList">
            {
              subscriptions.length > 0 && 
              (
                <div className="subItem">
                    <div className="subItem__id">Subscription ID</div>
                    <div className="subItem__nextPayment">Amount</div>
                    <div className="subItem__interval">Frequency</div>
                    <div className="subItem__dueDate">Due On</div>
                    <div className="subItem__status">Status</div>
                    <div className="subItem__buttons"></div>
                </div>
              )
            }
              
            {subscriptions.length > 0 ? 
                subscriptions.map(sub => (
                <div key={sub.id} className="subItem">
                    <div className="subItem__id">{sub.id}<SubscriptionNickname>{sub.metadata.nickname}</SubscriptionNickname></div>
                    <div className="subItem__nextPayment">${calculateSubscriptionPayment(sub)}</div>
                    <div className="subItem__interval">{determineFreq(sub.plan.interval, sub.plan.interval_count)}</div>
                    <div className="subItem__dueDate">{formatDate(sub.current_period_end, "MM/DD/YY")}</div>
                    <div className="subItem__status">{capitalize(sub.status)}</div>
                    <div className="subItem__buttons">
                        <button
                            onClick={() => cancel(sub.id)}
                            disabled={loading}
                            className="buttons__cancel"
                        >
                            Cancel
                        </button>
                    </div>
                </div>
                ))
                : <div className="subItem noSub">
                    <SubItemMessage isLicensedUser={!!isLicensedUser}>{isLicensedUser ? `You're a Current Licensed User` : 'No Active Subscription Found'}</SubItemMessage>
                    <SubItemDescription>
                      {
                        isLicensedUser ? 
                        `You currently have an active license that was purchased by another user.` : 
                        `There are currently no active subsriptions for this user.  If you would like to start a subscription for My UPC Finder, please select from the list of options above.`
                      }
                    </SubItemDescription>
                  </div>
            }
          </div>
        </div>
      </AuthCheck>
      {
        friendlyReminder === 1 && (
          <>
          <div className="signUp__reminderBg" onClick={()=>setFriendlyReminder(2)}></div>
          <div className="signUp__reminder">
            <div className="reminder__close" onClick={()=>setFriendlyReminder(2)}>X</div>
            <h3>Friendly Reminder</h3>
            <div className="reminder__body">
              <p>
              This email address: <span className="reminder__bodyEmail">{user?.email}</span>, must be also logged into Google Chrome and Chrome Sync turned on in order for the extension to work properly.
              </p>
              <p>
                For more information on how you can turn on Chrome Sync, please visit the following link: <a href="https://support.google.com/chrome/answer/185277?co=GENIE.Platform%3DDesktop&hl=en-GB" target="_blank">Turn sync on and off in Chrome</a>
              </p>
              <p>
                If <span className="reminder__bodyEmail">{user?.email}</span> is not the account you currently have logged into Chrome, you can either log into Chrome with this email address or you can log out of My UPC Finder and log back in with the Google Account that matches what you have logged into Chrome.
              </p>
            </div>
          </div>
          </>
        )
      }
      {
        showSubscribedModal && <SubscribedModal onConfirm={handleConfirmClick}/>
      }
    </>
  );
}

export default function Subscriptions({ user, settings }) {
  return (
    <Suspense fallback={'loading user'}>
      <SubscribeToPlan settings={settings} />
    </Suspense>
  );
}
