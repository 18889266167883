import React from 'react';
import './AffiliateDash.css';
import AffiliateView from './AffiliateView';
import FullAdminView from './FullAdminView';

function AffiliateDash({isAffiliate, isAdmin}) {
    return isAdmin ? ( 
        <FullAdminView /> 
    ) : ( 
        <AffiliateView affiliateId={isAffiliate?.aff_id} /> 
    )
}

export default AffiliateDash
