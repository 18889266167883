import React from 'react'
import { useUser } from 'reactfire';
import NonUserHome from './NonUserHome';
import UserHome from './UserHome';

function Home({ settings, setUserSettings }) {
    const user = useUser();
    console.log('state value user: ', user);
    return (
        <div className="home">
            {user ?
                (
                    <UserHome settings={settings} setUserSettings={setUserSettings} />
                )
                :
                (
                    <NonUserHome />
                )
            }
        </div>
    )
}

export default Home
