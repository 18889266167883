import React, { useState, useEffect } from 'react'
import { SignIn, SignOut } from './Customers';
import { useUser } from 'reactfire';
import './Header.css';
import SettingsSideBar from './SettingsSideBar';
import CountDownTimer from './CountDownTimer';

function Header({ settings }) {
    const user = useUser();
    const [ showSettingsSideBar, setShowSettingsSideBar ] = useState(false);
    const [ showFreeTrial, setShowFreeTrial ] = useState(false);
    const [ freeTrialEndDate, setFreeTrialEndDate ] = useState(0);
    
    useEffect(()=>{
        if(new Date().getTime() - settings?.created < 86400*1000*7){
            setShowFreeTrial(true);
            let time = (86400*1000*7) + settings?.created;
            setFreeTrialEndDate(time)
        }
    },[settings])

    return (
        <>
            <div className="header">
                {
                    showFreeTrial &&
                    (
                        <div className="header__freeTrial">
                            Your Free Trial Ends In: 
                            <div className="freeTrial__countdown">
                                <CountDownTimer 
                                    endDate={freeTrialEndDate}
                                />
                            </div>
                        </div>
                    )
                }
                <div className="nav">
                    <nav>
                        <ul className="navbar-nav">
                            <li>
                                {
                                    user ? 
                                    (
                                        <img src={user?.photoURL} alt="User Icon" onClick={()=>setShowSettingsSideBar(true)}/>
                                    ):
                                    (
                                        <SignIn />
                                    )
                                }
                            </li>
                        </ul>
                    </nav>
                </div>            
            </div>
            {
                user && 
                (
                    <SettingsSideBar user={user} show={showSettingsSideBar} set={setShowSettingsSideBar} userSettings={settings} />
                )
            }
        </>
    )
}

export default Header
