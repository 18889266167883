import React from 'react'
import { Link } from 'react-router-dom';
import './Sidebar.css';
import SubscriptionsIcon from '@material-ui/icons/Subscriptions';
import AssessmentIcon from '@material-ui/icons/Assessment';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import { SignOut } from './Customers';

function Sidebar({user, settings}) {
    return (
        <div className="sidebar">
            <nav>
                <ul>
                    <li>
                        <div className="logo">
                            <Link to="/">
                                <img src="./logo192.png" alt="Logo" />
                            </Link>
                        </div>
                    </li>
                    {
                        user && 
                        (
                            <li>
                                <Link to="/subscriptions" title="Subscriptions">
                                    <SubscriptionsIcon />
                                </Link>
                            </li>
                        )
                    }
                </ul>
            </nav>
            {
                user && 
                (
                    <div className="nav__settings"> 
                        <ul>
                            {
                                (settings?.settings?.isAdmin === 1 || settings?.settings?.isAffiliate === 1) &&
                                (
                                    <li>
                                        <Link to="/affiliate" title="Affiliate Dashboard">
                                            <AttachMoneyIcon />
                                        </Link>
                                    </li>
                                )
                            }
                            {
                                settings?.settings?.isAdmin === 1 && 
                                (
                                    <li>
                                        <Link to="/admin" title="Admin Settings">
                                            <AssessmentIcon />
                                        </Link>
                                    </li>
                                )
                            }
                            <li>
                                <SignOut user={user} useIcon={true} title="Logout"/>
                            </li>
                        </ul>
                    </div>
                )
            }
            
        </div>
    )
}

export default Sidebar
