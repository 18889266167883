import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { db } from '../firebase';
import { VictoryAxis, VictoryBar, VictoryChart, VictoryTheme, VictoryTooltip, VictoryLabel } from 'victory';
import { MONTH } from '../const';

const AVContainer = styled.div`
    padding: 15px 30px;
`;

const AVContainerHeading = styled.h1`
    margin-bottom: 20px;
`;

const AVStatsLine = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    background: #ffffff;
    box-shadow: 2px 3px 5px rgba(0,155,0,0.5);
    margin-bottom: 30px;
`;

const AVStatsContainer = styled.div`
    display: flex;
    flex-direction: column;
    border-right: 1px solid rgb(0,155,0);
    padding: 15px 45px;
    justify-content: center;
    align-items: center;
    flex-basis: 25%;

    &:last-child{
        border-right: 0;
    }
`;

const StatsContainerHeading = styled.div`
    text-align: center;
    padding: 10px 5px;
    font-size: 20px;
    font-weight: 700;
    line-height: 25px;
`;

const StatsContainerDetail = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 20px;
`;

const AVCharts = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const AVChartContainer = styled.div`
    width: 50%;
    max-height: 450px;
    box-sizing: border-box;
    padding: 0 45px;
    &:first-child{
        border-right: 1px solid rgb(0,155,0);
    }
`;

const ChartHeading = styled.div`
    font-size: 24px;
    text-align: center; 
    font-weight: 700;
`;

const ChartMessage = styled.div`
    display: block;
    margin-top: 50px;
    text-align: center;
    font-size: 24px;
    color: rgb(155,0,0);
    padding: 15px 45px;
    box-sizing: border-box;
`;

const AffiliateView = ({ affiliateId }) => {

    const [ affInfo, setAffInfo ] = useState(null);
    const [ totalSales, setTotalSales ] = useState(0.00);
    const [ totalCommission, setTotalCommission ] = useState(0.00);
    const [ totalPaid, setTotalPaid ] = useState(0.00);
    const [ chartData, setChartData ] = useState([]);
    const [ commissionChartData, setCommissionChartData ] = useState([]);

    const getAffTransactions = async (affId) => {
        if(affId) {
            const transactionInfo = {};
            let users = await db.collection('users').where('referId', '==', affId).get();
            let t = await db.collection('transactions').where('referral','==',affId).get();
            let aff = await db.collection('affiliates').where('code', '==', affId).get();
            if(t){
                transactionInfo.transactions = t.docs.map(doc => {
                    const data = doc.data();
                    const transactionId = doc.id;
                    return {transactionId, ...data}
                })
            }
            if(users) {
                transactionInfo.users = users.docs.map(user => user.data());
                transactionInfo.visitorCount = users.docs.reduce((accumulator, record) => accumulator + 1 , 0);
            }
            if(aff){
                transactionInfo.affiliate = {};
                aff.docs.forEach(doc => {
                    transactionInfo.affiliate = {aff_doc: doc.id, ...doc.data()};
                })
            }
            console.log('transactionInfo >>> ', transactionInfo);
            setAffInfo(transactionInfo);
            return true;
        }

        return false;
    }

    useEffect(() => {
        getAffTransactions(affiliateId);
    }, [affiliateId]);

    useEffect(() => {
        if (affInfo) {
            setTotalSales(parseFloat(affInfo.transactions?.reduce((accumulator, transaction) => {
                if(transaction.entry === 'credit') {
                    return accumulator += parseInt(transaction.amount);
                }
                if(transaction.entry === 'debit') {
                    return accumulator -= parseInt(transaction.amount);
                }
            }, 0) / 100).toFixed(2));

            setTotalPaid(parseFloat(affInfo.transactions?.reduce((acc, transaction) => {
                if(transaction.entry === 'credit' && transaction.payment?.paid) {
                    console.log('accumulator >>> ', acc += transaction.amount * affInfo.affiliate.fee_rate);
                    return acc += (transaction.amount*affInfo.affiliate.fee_rate);
                }
                return acc += 0.00;
            }, 0) / 100).toFixed(2));

            let tempChartData = [];
            affInfo.users?.forEach(user => {
                if (user.created) {
                    const monthNum = new Date(user.created).getMonth() + 1;
                    const year = new Date(user.created).getFullYear();
                    const recordIndex = tempChartData.findIndex(c => c.date === new Date(`${monthNum}-01-${year}`).getTime());
                    if (recordIndex > -1) {
                        tempChartData[recordIndex].count += 1;
                        tempChartData[recordIndex].label = `${MONTH[monthNum].SHORTNAME} '${year.toString().substring(2,4)}: ${tempChartData[recordIndex].count}`;
                    } else {
                        tempChartData.push({date: new Date(`${monthNum}-01-${year}`).getTime(), count: 1, label: `${MONTH[monthNum].SHORTNAME} '${year.toString().substring(2,4)}: ${1}`});
                    }
                }
            });
            
            tempChartData = tempChartData.map(data => { return { date: new Date(data.date), count: data.count, label: data.label }})
            console.log('temp chart data >>>', tempChartData);
            setChartData(tempChartData);

            let tempCommissionChartData = [];
            affInfo.transactions?.forEach(transaction => {
                if (transaction.date) {
                    const monthNum = new Date(transaction.date).getMonth() + 1;
                    const year = new Date(transaction.date).getFullYear();
                    const recordIndex = tempCommissionChartData.findIndex(t => t.date === new Date(`${monthNum}-01-${year}`).getTime());
                    const commission = (transaction.amount * affInfo.affiliate.fee_rate)/100;
                    console.log('commission rendered >>> ', commission);
                    if (recordIndex > -1) {
                        tempCommissionChartData[recordIndex].amount += parseFloat(commission);
                        tempCommissionChartData[recordIndex].label = `${MONTH[monthNum].SHORTNAME} '${year.toString().substring(2,4)}: $${tempCommissionChartData[recordIndex].amount.toFixed(2)}`;
                    } else {
                        tempCommissionChartData.push({ date: new Date(`${monthNum}-01-${year}`).getTime(), amount: commission, label: `${MONTH[monthNum].SHORTNAME} '${year.toString().substring(2,4)}: $${commission.toFixed(2)}`})
                    }
                }
            });
            tempCommissionChartData = tempCommissionChartData.map(data => { return { date: new Date(data.date), amount: data.amount, label: data.label }});
            console.log('commission chart data >>> ', tempCommissionChartData);
            setCommissionChartData(tempCommissionChartData);
        }
    }, [affInfo]);

    useEffect(() => {
        if (affInfo) {
            setTotalCommission(parseFloat(totalSales*affInfo.affiliate.fee_rate).toFixed(2));
        }
    }, [totalSales]);


    return (
        <AVContainer>
            <AVContainerHeading> Your Affiliate Dashboard - { affInfo?.affiliate?.display_name } </AVContainerHeading>
            <AVStatsLine>
                <AVStatsContainer>
                    <StatsContainerHeading>Total Visitors</StatsContainerHeading>
                    <StatsContainerDetail>{ affInfo?.visitorCount || 0 }</StatsContainerDetail>
                </AVStatsContainer>
                <AVStatsContainer>
                    <StatsContainerHeading>Total Sales</StatsContainerHeading>
                    <StatsContainerDetail>${ totalSales }</StatsContainerDetail>
                </AVStatsContainer>
                <AVStatsContainer>
                    <StatsContainerHeading>Total Commission</StatsContainerHeading>
                    <StatsContainerDetail>${ totalCommission }</StatsContainerDetail>
                </AVStatsContainer>
                <AVStatsContainer>
                    <StatsContainerHeading>Total Paid</StatsContainerHeading>
                    <StatsContainerDetail>${ totalPaid }</StatsContainerDetail>
                </AVStatsContainer>
            </AVStatsLine>
            <AVCharts>
                <AVChartContainer>
                    <ChartHeading>New Visitor History</ChartHeading>
                    {
                        chartData.length > 1 ? (
                            <VictoryChart theme={VictoryTheme.material} domainPadding={{ x: 10, y: 10 }} >
                                <VictoryAxis 
                                    axisLabelComponent={<VictoryLabel dy={20}/>}
                                    label="Date"
                                    tickFormat={x => `${new Date(x).getMonth() + 1}/${new Date(x).getFullYear()}`}
                                    theme={VictoryTheme.material}
                                />
                                <VictoryAxis 
                                    dependentAxis 
                                    axisLabelComponent={<VictoryLabel dy={-25}/>}
                                    label="New Visitor Count"
                                    tickFormat={y => parseInt(y)}
                                    theme={VictoryTheme.material}
                                />
                                <VictoryBar 
                                    labelComponent={<VictoryTooltip />}
                                    data={chartData}
                                    x="date"
                                    y="count"
                                />
                            </VictoryChart>
                        ) : (
                            <ChartMessage>
                                New Visitor Information Needs to Span More Than 1 Month to View Here
                            </ChartMessage>
                        )
                    }
                    
                </AVChartContainer>
                <AVChartContainer>
                    <ChartHeading>Commission History</ChartHeading>
                    {
                        commissionChartData.length > 1 ? (
                            <VictoryChart theme={VictoryTheme.material} domainPadding={{ x: 20, y: 20 }} width={400}>
                                <VictoryAxis 
                                    label="Date"
                                    axisLabelComponent={<VictoryLabel dy={20}/>}
                                    tickFormat={x => `${new Date(x).getMonth() + 1}/${new Date(x).getFullYear()}`}
                                    theme={VictoryTheme.material}
                                />
                                <VictoryAxis 
                                    dependentAxis 
                                    axisLabelComponent={<VictoryLabel dy={-25}/>}
                                    label="Commission Earned ($)" 
                                    domain={{y: [0, true]}}
                                    theme={VictoryTheme.material}
                                />
                                <VictoryBar 
                                    labelComponent={<VictoryTooltip />}
                                    data={commissionChartData}
                                    x="date"
                                    y="amount"
                                />
                            </VictoryChart>
                        ) : (
                            <ChartMessage>
                                Commission History Needs to Span More Than 1 Month to View Here.
                            </ChartMessage>
                        )
                    }
                   
                </AVChartContainer>
            </AVCharts>
        </AVContainer>
    )
}

export default AffiliateView;