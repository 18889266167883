import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { FirebaseAppProvider } from 'reactfire';
import { firebaseConfig } from './firebase';

console.log('detect url from index >> ', window.location.href);

export const stripePromise = loadStripe(
  window.location.href.includes('localhost') ? 
  'pk_test_51HPwFlBI2czU4IFnLnQSF3QGVxshMeKTFmSD5aJrgFy0kafboN0UaHL1ZRQVOv7VpPI7dDRTZzpqnEh20b7wgk8f00Pt8bYZsd' : 
  'pk_live_51HPwFlBI2czU4IFnUt5fyYVSzh1ZpwZWGWArwcaj80LsvovZv8EK7Lt6wK5L9NCJYR9WBlQWWEgnYZfMJJbh6QOy00RWWRDyNT'
);

ReactDOM.render(
  <React.StrictMode>
      <FirebaseAppProvider firebaseConfig={firebaseConfig}>
        <Elements stripe={stripePromise}>
          <Suspense fallback={""}>
            <App />
          </Suspense>
        </Elements>
      </FirebaseAppProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
