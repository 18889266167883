import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { fetchFromAPI, getProductID, determineFreq } from '../helpers';

const OptionsContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px auto;
    width: 100%;
`;

const ProductContainer = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: 10em;
    border-width: 1px;
    border-style: solid;
    border-color: ${props => props.selected ? 'rgb(0,155,0)' : '#000000'};
    box-shadow: 0 3px 6px ${props => props.selected ? 'rgba(0,155,0,0.25)' : 'rgba(0,0,0,0.25)'};
    flex-basis: 20%;
    margin: 0 5px;
    cursor: ${props => props.onClick ? 'pointer' : 'not-allowed'};
    border-radius: 10px;
`;

const ProductPrice = styled.div``;

const ProductName = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    text-align: center;
    padding: 0.5em 0;
`;

const SubscriptionLicenseOptions = ({ licenseType, onClick }) => {

    const productId = getProductID(licenseType);
    const [ productDetail, setProductDetail ] = useState(null);
    const [ licenseOptions, setLicenseOptions ] = useState([]);
    const [ selectedLicense, setSelectedLicense ] = useState(null);

    const getProductDetails = async (productId) => {
        if (productId) {
            const productDetails = await fetchFromAPI(`product/details/${encodeURI(productId)}`, {method: 'GET'});
            if (productDetails) {
                setProductDetail(productDetails.data);
            }
        }
    }

    const handleClick = (product, index) => {
        setSelectedLicense(index);
        onClick(product);
    }

    useEffect(() => {
        getProductDetails(productId);
    }, [])

    useEffect(() => {
        if (productDetail) {
            console.log('product details >> ', productDetail);
        }
    }, [productDetail]);

    return (
        <OptionsContainer>
            { 
                productDetail?.filter(prod => prod.active).sort((a,b) => a.unit_amount - b.unit_amount).map((product, index) => {
                    return (
                        <ProductContainer onClick={() => handleClick(product, index)} key={product.id} selected={selectedLicense === index}>
                            <ProductName>
                                { determineFreq(product.recurring.interval, product.recurring.interval_count) }
                            </ProductName>
                            <ProductPrice>
                                ${ (product.unit_amount/100).toFixed(2) }
                            </ProductPrice>
                        </ProductContainer>
                    )
                })
            }
        </OptionsContainer>
    )
}

export default SubscriptionLicenseOptions;