import React from 'react';
import styled from 'styled-components';


const ModalBG = styled.div`
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0,0,0,0.75);
    z-index: 98
`;
const ModalBody = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    padding: 30px;
    background: #ffffff;
    border-radius: 10px;
    width: 400px;
    min-height: 250px;
    box-shadow: 0 3px 6px rgba(0,0,0,0.25);
    z-index: 99;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const ModalContainer = styled.div`
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
`;

const ModalTitle = styled.div`
    font-size: 22px;
    font-weight: 700;
    color: rgb(0,155,0);
    margin-bottom: 15px;
`;

const ModalDescription = styled.div`
    margin-bottom: 20px;
`;

const ModalButtonGroup = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

const ModalConfirmButton = styled.button`
    padding: 0.5em 3em;
    text-align: center;
    font-size: 17px;
    font-weight: 700;
    text-transform: uppercase;
    background: rgb(0,155,0);
    color: #ffffff;
    border: 1px solid rgb(0,155,0);
    transition: all 200ms ease-in-out;
    cursor: pointer;
    flex-grow: 1;
    margin-right: 1em;
    &:hover{
        background: #ffffff;
        color: rgb(0,155,0);
    }
`;

const DownloadInvoice= styled.a`
    text-decoration: none;
    color: rgb(0,155,0);
    font-weight: 700;
`;

const SnarkyComment = styled.span`
    color: #555555;
    font-style: italic;
    font-size: 15px;
`;

const SubscribedModal = ({ onConfirm, invoiceLink }) => {

    const handleConfirmClick = (e) => {
        e.preventDefault();
        onConfirm();
    }

    return (
        <ModalContainer>
            <ModalBody>
                <ModalTitle>
                    Subscription Successfully Purchased!
                </ModalTitle>
                <ModalDescription>
                    You can now use the My UPC Finder Chrome extension as much as you would like! <SnarkyComment>(For the given billing cycle that is)</SnarkyComment>
                </ModalDescription>
                <ModalButtonGroup>
                    <ModalConfirmButton onClick={(e) => handleConfirmClick(e)}>
                        Got It!
                    </ModalConfirmButton>
                    <DownloadInvoice href={invoiceLink} download>
                        Download Invoice
                    </DownloadInvoice>
                </ModalButtonGroup>
            </ModalBody>
            <ModalBG onClick={(e) => handleConfirmClick(e)}/>
        </ModalContainer>
    )
}

export default SubscribedModal