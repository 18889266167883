import React from 'react'
import { Link } from 'react-router-dom'
import './Footer.css';

function Footer() {
    return (
        <div className="footer">
            <div className="left">
                &copy; Copyright 2021. All Rights Reserved.
            </div>
            <div className="right">
                <Link to="privacy-policy">
                    Privacy Policy
                </Link>
                <Link to="/terms-of-service">
                    Terms of Service
                </Link>
                <Link to="/affiliate-signup">
                    Affiliate
                </Link>
                <Link to="/contact">
                    Contact
                </Link>
            </div>
        </div>
    )
}

export default Footer 
