import React, { useState, useEffect } from 'react'
import './Dashboard.css';
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    PieChart,
    Pie,
    Cell
  } from "recharts";

function Dashboard({users, referrals}) {
    const [activePlanCount, setActivePlanCount] = useState(0);
    const [planUserGroup, setPlanUserGroup] = useState([]);
    const [yearly, setYearly] = useState(0);

    useEffect(()=>{
        let count = 0;
        let yearlyTotal = 0;
        let userGroup = [
            {
                name: "Monthly",
                amt: 0,
                multiplier:{
                    rate: 7,
                    yearly: 12
                }
            },
            {
                name: "Quarterly",
                amt: 0,
                multiplier:{
                    rate: 20,
                    yearly: 4
                }
            },
            {
                name: "Bi-Yearly",
                amt: 0,
                multiplier:{
                    rate: 40,
                    yearly: 2
                }
            },
            {
                name: "Yearly",
                amt: 0,
                multiplier:{
                    rate: 79,
                    yearly: 1
                }
            }
        ]

        console.log('users: ', users);

        users.forEach((ref,index)=>{
            if(ref.activePlan.id.length > 0){
                count++;
                let days = (ref.activePlan.end - ref.activePlan.start)/1000/60/60/24;
                if(days > 0 && days <= 32){
                    userGroup[0].amt++;
                    yearlyTotal += userGroup[0].multiplier.rate * userGroup[0].multiplier.yearly;
                }else if(days > 32 && days <= 92){
                    userGroup[1].amt++
                    yearlyTotal += userGroup[1].multiplier.rate * userGroup[1].multiplier.yearly;
                }else if(days > 92 && days <= 182){
                    userGroup[2].amt++
                    yearlyTotal += userGroup[2].multiplier.rate * userGroup[2].multiplier.yearly;
                }else if(days > 182 && days <= 367){
                    userGroup[3].amt++
                    yearlyTotal += userGroup[3].multiplier.rate * userGroup[3].multiplier.yearly;
                }
            }
        });

        setYearly(yearlyTotal);
        setActivePlanCount(count);
        setPlanUserGroup(userGroup);

    },[users])
    
    const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

    const RADIAN = Math.PI / 180;
    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index, name }) => {
      const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
      const x = cx + (radius*2.75) * Math.cos(-midAngle * RADIAN);
      const y = cy + (radius*2.75) * Math.sin(-midAngle * RADIAN);
    
      return (
        <text x={x} y={y} fill="black" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
          {`${name} (${(percent * 100).toFixed(0)}%)`}
        </text>
      );
    };



    return (
        <div className="dashboard">
            <h1>Statistics</h1>
            <div className="dashboard_info">
                <div className="dashboard_item">
                    <h3>Total Users</h3>
                    <p>{users.length}</p>
                </div>
                <div className="dashboard_item">
                    <h3>Total Active Plans</h3>
                    <p>{activePlanCount}</p>
                </div>
            </div>
            <div className="dashboard_info">
                <div className="dashboard_item">
                    <h2>Active Plan Breakdown</h2>
                    <PieChart
                        width={500}
                        height={400}
                    >
                        <Tooltip />
                        <Pie 
                            dataKey="amt" 
                            data={planUserGroup} 
                            cx="50%" 
                            cy="50%" 
                            outerRadius={100} 
                            fill="rgb(0, 155, 0)" 
                            label={renderCustomizedLabel}
                        >
                            {planUserGroup.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                            ))}
                        </Pie>
                    </PieChart>
                </div>
                <div className="dashboard_item">
                    {
                        
                        planUserGroup?.map((group, groupIndex) => {
                            return (
                                <div className="item_row">
                                    <div className="label">
                                        {group.name}
                                    </div>
                                    <div className="value">
                                        ${(group.amt * group.multiplier.rate).toFixed(2)}
                                    </div>
                                    <div className="yearly_figure">
                                       ${(group.amt*group.multiplier.rate*group.multiplier.yearly).toFixed(2)} / year
                                    </div>
                                </div>
                            )
                        })
                    }
                    <div className="item_row">
                        <div className="yearly_label">
                            Total Per Year:
                        </div>
                        <div className="yearly_figure">
                            ${yearly.toFixed(2)}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Dashboard
