export const CardElementStyle = {
    style: {
        base: {
            iconColor: 'rgb(0,155,0)',
            fontSize: '17px',
            fontWeight: 500,
            color: '#333',
            '::placeholder': {
                color: 'rgb(0,155,0)'
            },
            child: {
                width: '100%'
            },
            border: '2px solid rgba(0,0,0,0.25)',
            position: 'relative'
        },
        invalid: {
            iconColor: 'rgb(200,0,0)',
            color: 'rgb(200,0,0)'
        },
        complete: {
            iconColor: '#333',
            color: '#333'
        }
    }
}