import { useMemo } from 'react';
import { ENV_TYPE, PRODUCT_IDS } from './const';
import { auth } from './firebase';

const API = window.location.href.includes('localhost') ? 'https://stripe-backend-test-u25h2na3la-uk.a.run.app' : 'https://stripe-backend-u25h2na3la-uk.a.run.app';
/**
 * A helper function to fetch data from your API
 */
export async function fetchFromAPI(endpointURL, opts){
    const {method, body} = {method: 'POST', body: null, ...opts}

    const user = auth.currentUser;
    const token = user && (await user.getIdToken());
    console.log('token: ', token);
    const res = await fetch(`${API}/${endpointURL}`, {
        method,
        ...(body && {body: JSON.stringify(body) }),
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        }
    });
    return res.json();
}

export function capitalize(s){
    if(typeof s !== 'string') return '';
    return s.charAt(0).toUpperCase() + s.slice(1);
}

export function convertJSMonthNum(number, format){
    let num = "";
    switch(number){
        case 0: 
            num = format==="string" ? "01" : 1;
            break;
        case 1:
            num = format==="string" ? "02" : 2;
            break;
        case 2: 
            num = format==="string" ? "03" : 3;
            break;
        case 3:
            num = format==="string" ? "04" : 4;
            break;
        case 4: 
            num = format==="string" ? "05" : 5;
            break;
        case 5: 
            num = format==="string" ? "06" : 6;
            break;
        case 6:
            num = format==="string" ? "07" : 7;
            break;
        case 7:
            num= format==="string" ? "08" : 8;
            break;
        case 8:
            num= format==="string" ? "09" : 9;
            break;
        case 9:
            num=format==="string" ? "10" : 10;
            break;
        case 10:
            num=format==="string" ? "11" : 11;
            break;
        case 11:
            num=format==="string" ? "12" : 12;
            break;
        default:
            num = 0;
            break;

    }
    return num;
}

function convertJSDayNum(num){
    let number = "";
    if(num >= 0 && num <= 9){
        number = `0${num}`;
    }else{
        number = num;
    }
    return number
}

export function formatDate(date, format){
    let dateNum = date.toString().length < 13 ? date*1000 : date;
    let month = convertJSMonthNum(new Date(dateNum).getMonth(), "string");
    let day = convertJSDayNum(new Date(dateNum).getDate());
    let year = new Date(dateNum).getFullYear();
    return `${month}/${day}/${year}`
}

export const useQuery = (useLocation) => {
    const { search } = useLocation;

    return useMemo(() => new URLSearchParams(search), [search]);
} 

export const getProductID = (licenseType) => {
    const location = window.location.href;
    console.log('getProducID location >> ', location);
    if (location.includes('localhost')) {
        console.log('location is dev', PRODUCT_IDS[ENV_TYPE.DEV][licenseType]);
        return PRODUCT_IDS[ENV_TYPE.DEV][licenseType];
    } else {
        return PRODUCT_IDS[ENV_TYPE.PROD][licenseType];
    }
}

export const determineFreq = (interval, interval_count) => {
    if(interval === "month"){
      switch(interval_count){
        case 1:
          return "Monthly";
        case 3:
          return "Quarterly";
        case 6:
          return "Bi-Yearly";
        case 12:
          return "Yearly"
        default:
          return "Unknown";
      }
    } else if (interval === 'week') {
      switch(interval_count) {
        case 1: 
          return 'Weekly';
        case 2: 
          return 'Bi-Weekly';
        default:
          return 'Unknown';
      }
    } else if (interval === 'year') {
        switch(interval_count) {
            case 1:
                return 'Yearly';
            default:
                return 'Unknown';
        }
    }

    return 'No Interval Found';
}