import { CompassCalibrationOutlined } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { db } from '../firebase';
import './SettingsSideBar.css';

function SettingsSideBar({user, show, set, userSettings}) {
    //Local State
    const [settings, setSettings] = useState({});
    const [referralList, setReferralList] = useState([]);
    const [editInfo, setEditInfo] = useState(false);

    useEffect(() => {
        async function fetchSettings(){
            if(user){
                await getSettings();
                await getReferralList();
            }
        }
        fetchSettings();
    },[userSettings]);

    //Functions
    const getSettings = async () => {
        const userSettings = (await db.collection('users').doc(user.uid).get()).data();
        console.log('user settings: ', userSettings);
        if(userSettings){
            setSettings(userSettings);
        }
    }

    const getReferralList = async () => {
        const refList = await db.collection('affiliates').where("status","==","approved").get();
        if(refList){
            setReferralList(refList.docs.map(refDoc=>{
                const refData = refDoc.data();
                const refId = refDoc.id;
                return {refId, ...refData};
            }))
        }
    }

    const saveSettings = async () => {
        await db.collection('users').doc(user.uid).set(settings, {merge: true});
        setEditInfo(false);
    }

    const handleChange = (change, mode) => {
        if(mode.indexOf('settings')>-1){
            setSettings(change);
        }else if(mode.indexOf('test')>-1){
            console.log('test change >>> ', change);
        }
    }

    const handleClose = () => {
        setEditInfo(false);
        set(false);
    }

    // const syncSettings = async () => {
    //     let users = await db.collection('users').get();
    //     let referrals = await db.collection('referrals').get();
    //     let matchCount = 0;
    //     for(let i = 0; i < users.docs.length; i++){
    //         for(let j = 0; j < referrals.docs.length; j ++){
    //             let userSettings;
    //             if(users.docs[i].id === referrals.docs[j].id){
    //                 matchCount++;
    //                 userSettings = users.docs[i].data();
    //                 userSettings.referId = referrals.docs[j].data().code;
    //                 console.log('user/referral match >>> ', userSettings);
    //                 await db.collection('users').doc(users.docs[i].id).set(userSettings, {merge: true});
    //                 console.log('updated user settings >>> ', users.docs[i].id);
    //                 break;
    //             }
    //         }
    //     }
    //     console.log('match count >>> ', matchCount);
    // }

    return (
        <div className={`settingsSideBar ${!show ? "hide" : "show"}`}>
            <div className="settingsSideBar__close" onClick={()=>handleClose()}>
                Close
            </div>
            <div className="settingsSideBar__body">
                <div className="body__userInfo">
                    <img src={user?.photoURL} alt="user icon"/>
                    <div className="userInfo__details">
                        {
                            !editInfo ? 
                            (
                                <>
                                    <div className="details__name">{settings.firstName} {settings.lastName}</div>
                                    <div className="details__email">{settings.email}</div>
                                </>
                            ):
                            (
                                <>
                                    <div className="peronsalInfo__form">
                                        <input type="text" name="firstName" placeholder="First Name" value={settings?.firstName} onChange={(e) => handleChange({...settings, firstName: e.target.value}, "settings")} />
                                        <input type="text" name="lastName" placeholder="Last Name" value={settings?.lastName} onChange={(e) => handleChange({...settings, lastName: e.target.value}, "settings")} />
                                    </div>
                                    <div className="details__email">{settings.email}</div>
                                </>
                            )
                        }
                        
                    </div>
                </div>
                <div className="body__referrer">
                    <div className="referrer__title">
                        <h3>Referrer</h3>
                        <span className="tooltip">?<div className="tooltip__info">If you were referred here from a certain group or individual, please select them from the drop down list so they can get credit for the referral.</div></span>
                    </div>
                    <div className="referrer__value">
                        {
                            !editInfo ? 
                            (
                                (settings?.referId === "" || !settings?.referId) ? "No Referrer" : settings?.referId
                            ):
                            (
                                <select value={settings.referId} onChange={(e)=>handleChange({...settings, referId: e.target.value},"settings")}>
                                    <option value="">No Referrer</option>
                                    {
                                        referralList?.map((referrer, index) => {
                                            return(
                                                <option value={referrer.code} key={`referral_list_${index}`}>{referrer.display_name}</option>
                                            )
                                        })
                                    }
                                </select>
                            )
                        }
                    </div>
                </div>
                <div className="body__darkMode">
                    <h3>Dark Mode</h3>
                    <div className="darkMode__value">
                        {
                            !editInfo ? 
                            (
                                settings?.settings?.isDarkMode === 1 ? "On" : "Off"
                            ):
                            (
                                <select value={settings?.settings?.isDarkMode} onChange={(e)=>handleChange({...settings, settings:{...settings.settings, isDarkMode: parseInt(e.target.value)}}, "settings")}>
                                    <option value={0}>Off</option>
                                    <option value={1}>On</option>
                                </select>
                            )
                        }
                    </div>
                </div>
                <div className="body__apiKey">
                    <h3>API Key</h3>
                    <div className="apiKey__value">
                        {
                            user.uid
                        }
                    </div>
                </div>
            </div>          
            <div className="form__buttonGroup">
                {
                    !editInfo ? (
                        <div className="edit__button" onClick={()=>setEditInfo(true)}>Edit</div>
                    ):
                    (
                        <>
                            <div className="submit__button" onClick={()=>saveSettings()}>Save</div>
                            <div className="cancel__button" onClick={()=>handleClose()}>Cancel</div>
                        </>
                    )
                }
                
                
            </div>
        </div>
    )
}

export default SettingsSideBar
