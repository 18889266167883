import React, { useState } from 'react'
import './NonUserSteps.css';
import {SignIn} from './Customers';

function NonUserSteps() {
    const [stepSelected, setStepSelected] = useState(0);
    const steps = {
        1: {
            name: "Sign In"
        },
        2: {
            name: "Find Your Plan"
        },
        3: {
            name: "Subscribe"
        }
    }

    return (
        <div className="nonUserSteps">
            <h1>Get Started in 3 Easy Steps:</h1>
            <p>Click on a step below to see more details.</p>
            <div className="nonUserSteps__list">
                <ul>
                    <li onClick={()=>setStepSelected(1)}>
                        <div className="list__itemHeader">
                            <div className="itemHeader__number">
                                1
                            </div>
                            <div className="itemHeader__title">
                                Sign In
                            </div>
                        </div>
                        <div className="list__itemBody">
                            <p>
                                Sign into our system with a Google account. This is a Chrome Extension subscription so you'll need one anyways.
                            </p>
                            <SignIn />
                        </div>
                    </li>
                    <li onClick={()=>setStepSelected(2)}>
                        <div className="list__itemHeader">
                            <div className="itemHeader__number">
                                2
                            </div>
                            <div className="itemHeader__title">
                                Find Your Plan
                            </div>
                        </div>
                        <div className="list__itemBody">
                            <p>
                                There are a couple of pricing options and frequencies to choose from.  Pick the option that best suits your needs.
                            </p>
                        </div>
                    </li>
                    <li onClick={()=>setStepSelected(3)}>
                        <div className="list__itemHeader">
                            <div className="itemHeader__number">
                                3
                            </div>
                            <div className="itemHeader__title">
                                Subscribe &amp; Go
                            </div>
                        </div>
                        <div className="list__itemBody">
                            <p>
                                Once you have chosen your pricing &amp; frequency option, you can enter your card information and subscribe.
                            </p>
                        </div>
                    </li>

                </ul>
            </div>
            {
                stepSelected > 0 && 
                (
                    <div className="nonUserSteps__details">
                        <h2>Step {stepSelected}: {steps[stepSelected].name}</h2>
                        {
                            stepSelected === 1 &&
                            (
                                <div className="details__stepOne">
                                    <p>
                                        My UPC Finder is a Google Chrome Extension which requires Google Chrome Browser in order to work properly.  Because of this, we ask that you login to your account with us as a Google account only to align with the requirements from the product itself.  If you already have a Google Account you would like to use, please click the following button to get started: <SignIn />
                                    </p>
                                    <div className="issuesContainer">
                                        <div className="issuesContainer__issue">
                                            <h3>Don't have a Google Account?</h3>
                                            <p>
                                                No problem! If you do not currently have a Google account, please click on the following link to sign up for a free Google Account: <a href="https://accounts.google.com/signup/v2/webcreateaccount?flowName=GlifWebSignIn&flowEntry=SignUp" target="_blank" rel="noreferrer" className="button">Sign Up for a Google Account</a>
                                            </p>
                                        </div>
                                        <div className="issuesContainer__issue">
                                            <h3>Don't have Google Chrome?</h3>
                                            <p>
                                                That's not a problem either!  If you do not currently have Google Chrome installed, you can download and install it from the following link: <a href="https://www.google.com/chrome/" target="_blank" rel="noreferrer" className="button">Download Google Chrome</a>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        {
                            stepSelected === 2 &&
                            (
                                <div className="details__stepTwo">
                                    <p>
                                        My UPC Finder comes with a couple of different subscription models to choose from.  The more months you purchase up front, the cheaper the average monthly rate becomes. Once signed in you can access this page view the "Subscriptions" link in the menu.
                                    </p>
                                </div>
                            )
                        }
                        {
                            stepSelected === 3 &&
                            (
                                <div className="details__stepThree">
                                    <p>
                                        After choosing your pricing option, you will have the ability to enter your credit card information and submit to our Stripe subscription service.  You will continue to be billed at the frequency you chose until you decide to cancel your service with us. Once subscribed, if you have not already done so, please visit our Chrome Web Store listing to install the My UPC Finder Chrome Extension.
                                    </p>
                                </div>
                            )
                        }
                    </div>
                )
            }
            
        </div>
    )
}

export default NonUserSteps
