import React from 'react'

function CheckoutFail() {
    return (
        <div className="checkout__fail">
            <h3>Checkout Failed!</h3>
        </div>
    )
}

export default CheckoutFail
