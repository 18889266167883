import React, { useEffect, useState } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import './App.css';
import Subscriptions from './components/Subscriptions';
import CheckoutSuccess from './components/CheckoutSuccess';
import CheckoutFail from './components/CheckoutFail';
import Home from './components/Home'; 
import Header from './components/Header';
import Sidebar from './components/Sidebar';
import NonUserSteps from './components/NonUserSteps';
import { useUser } from 'reactfire';
import AdminDashboard from './adminComponents/AdminDashboard';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsOfService from './components/TermsOfService';
import Footer from './components/Footer';
import AffiliateDash from './affiliateComponents/AffiliateDash';
import { db } from './firebase';
import AffiliateSignUp from './affiliateComponents/AffiliateSignUp';
import ContactUs from './supportComponents/ContactUs';
import AffiliateReconciliation from './affiliateComponents/AffiliateReconciliation';
import SubscriptionsAddLicense from './components/SubscriptionsAddLicense';

function App() {
  const activeUser = useUser();
  //Local State
  const [user, setUser] = useState();
  const [userSettings, setUserSettings] = useState();
  const [isAdmin, setIsAdmin] = useState(false);
  const [isAffiliate, setIsAffiliate] = useState(false);

  //Listener
  useEffect(()=>{
    setUser(activeUser);
    console.log('USE USER >>>> ', user);
    // Get User Data from firebase
    if(user){
      async function getSettings(){
          const userSettings = (await db.collection('users').doc(user.uid).get()).data();
          console.log('USER SETTINGS >>> ', userSettings, userSettings);
          userSettings.settings.isAdmin && setIsAdmin(true);
          userSettings.settings.isAffiliate && setIsAffiliate({aff_id: userSettings.settings.affiliateId, isAffiliate: true});
          setUserSettings(userSettings);
      }
      getSettings();
    }
  },[user, activeUser]);

  return (
      <BrowserRouter>
      <div className={`app ${userSettings?.settings?.isDarkMode === 1 ? "dark" : "light"}`}>
          <Sidebar 
            user={user}
            settings={userSettings}
          />
        <main>
          <Header settings={userSettings} />
          <Switch>
            {/* <Route path="/checkout">
              <Checkout />
            </Route>
            <Route path="/payments">
              <Payments />
            </Route> */}
            {/* <Route path="/customers">
              <Customers /> 
            </Route> */}
            <Route path="/contact">
              <ContactUs user={user} />
            </Route>
            <Route path="/privacy-policy">
              <PrivacyPolicy />
            </Route>
            <Route path="/terms-of-service">
              <TermsOfService />
            </Route>
            <Route path="/affiliate-signup">
              <AffiliateSignUp user={user} />
            </Route>
            <Route exact path="/subscriptions">
              <Subscriptions user={user} settings={userSettings} />
            </Route>
            <Route exact path="/subscriptions/licenses">
              <SubscriptionsAddLicense user={user} />
            </Route>
            <Route path="/success">
              <CheckoutSuccess />
            </Route>
            <Route path="/failed">
              <CheckoutFail />
            </Route>
            <Route path="/steps">
              <NonUserSteps />
            </Route>
            <Route path="/admin">
              <AdminDashboard isAdmin={isAdmin}/>
            </Route>
            <Route exact path="/affiliate">
              <AffiliateDash isAffiliate={isAffiliate} isAdmin={isAdmin}/>
            </Route>
            <Route exact path="/affiliate/reconcile/:id">
              <AffiliateReconciliation user={user} />
            </Route>
            <Route path="/">
                <Home settings={userSettings} setUserSettings={setUserSettings} />
            </Route>
          </Switch>
        </main>
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
