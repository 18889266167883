import React,{useState,useEffect} from 'react'
import { useHistory } from 'react-router-dom';
import './ManageAffiliates.css';
import WarningIcon from '@material-ui/icons/Warning';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { capitalize, fetchFromAPI } from '../helpers';
import { db } from '../firebase';

function ManageAffiliates({affiliates}) {
    const history = useHistory();
    const [ affList, setAffList ] = useState(affiliates);
    const [ viewAff, setViewAff ] = useState();
    const [ editAff, setEditAff ] = useState(false);
    const [ showApprovalDialogue, setShowApprovalDialogue ] = useState(false);
    const [ approvalPercent, setApprovalPercent ] = useState(0);
    const [ approvalCode, setApprovalCode ] = useState("");
    const [ disableApprovalSubmit, setDisableApprovalSubmit ] = useState(false);
    const [ showSendMessage, setShowSendMessage ] = useState(false);
    const [ message, setMessage ] = useState("");
    const [ subject, setSubject ] = useState("");

    useEffect(()=>{
        setAffList(affiliates);
        console.log('affiliate list >>> ', affList);
    },[affiliates, affList])

    const handleViewAff = (index) => {
        setViewAff(affList[index]);
    } 

    const handleApproval = async () => {
        console.log('clicked on approve', viewAff.firebaseId);
        let data = {
            firebaseId: viewAff.firebaseId,
            email: viewAff.email.notifications,
            fee_rate: (approvalPercent/100).toFixed(4),
            code: approvalCode
        }
        let approval = await fetchFromAPI('affiliates/approve', {
            method: "POST",
            body: data
        });
        if(approval){
            let affiliate = viewAff;
            affiliate.fee_rate = (approvalPercent/100).toFixed(4);
            affiliate.status = "approved";
            affiliate.code = approvalCode;
            setViewAff(affiliate);
            setApprovalPercent(0);
            setShowApprovalDialogue(false);
        }
    }

    const handleDenial = async () => {
        console.log('clicked on deny');
    }

    const handleReconcile = async () => {
        console.log('clicked on reconcile');
        history.push(`/affiliate/reconcile/${viewAff?.code}`)
    }

    const handleSendMessage = async () => {
        console.log('clicked on send message');
        setShowSendMessage(true);
    }

    const handleSentMessage = async () => {
        const body = {
            subject: subject,
            message: message,
            email: viewAff.email.notifications
        };
        let sendMessage = await fetchFromAPI('affiliates/send', {
            method: "POST",
            body: body
        });
        if(sendMessage.email){
            handleCancelMessage();
        }
    }

    const handleCodeChange = async (value) => {
        let regex = /^[a-zA-z0-9][\S]+$/g;
        if(regex.test(value)){
            console.log('code name good to go');
            setDisableApprovalSubmit(false);
        }else{
            console.log('code name not allowed');
            setDisableApprovalSubmit(true);
        }
        setApprovalCode(value);
    }

    const handleAffEdit = async (value, directory) => {
        let currentAff = viewAff;
        currentAff[directory] = value;
        setViewAff(currentAff);
    }

    const handleAffSave = async () => {
        await db.collection('affiliates').doc(viewAff.firebaseId).update({
            display_name: viewAff.display_name,
            contact_name: viewAff.contact_name,
            email:{
                notifications: viewAff.email.notifications,
                owner: viewAff.email.owner
            },
            paypal_id: viewAff.paypal_id
        });
        setEditAff(false);
    }

    const handleCancelMessage = () => {
        setMessage("");
        setSubject("");
        setShowSendMessage(false);
    }

    return (
        <>
            <div className="manageAffiliates">
                <h1>Affiliates</h1>
                <div className="manageAffiliates__list">
                    <div className="list__header">
                        <div className="header__title">Status</div>
                        <div className="header__title">Company Name</div>
                        <div className="header__title">Owner</div>
                        <div className="header__title">Email</div>
                    </div>
                    <div className="list__body">
                        {
                            affList?.map((aff,affIndex)=>{
                                return (
                                    <div className="body__row" key={`aff_body_row_${affIndex}`} onClick={()=>handleViewAff(affIndex)}>
                                        <div className="body__value status">
                                            {
                                                aff.status === "pending" && 
                                                (
                                                    <div className="status__pending">
                                                        <WarningIcon />
                                                    </div>
                                                )
                                            }
                                            {
                                                aff.status === "approved" && 
                                                (
                                                    <div className="status__approved">
                                                        <VerifiedUserIcon />
                                                    </div>
                                                )
                                            }
                                            {
                                                aff.status === "declined" && 
                                                (
                                                    <div className="status__declined">
                                                        <HighlightOffIcon />
                                                    </div>
                                                )
                                            }
                                        </div>
                                        <div className="body__value">{aff.display_name}</div>
                                        <div className="body__value">{aff.contact_name}</div>
                                        <div className="body__value">{aff.email.owner}</div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                <div className="manageAffiliates__view">
                    {
                        viewAff && 
                        (
                            <>
                                <h2>Details</h2>
                                <div className="view__container">
                                    <div className="view__left">
                                        <div className="left__row">
                                            <div className="row__title">
                                                Firebase ID:
                                            </div>
                                            <div className="row__value">
                                                {viewAff?.firebaseId}
                                            </div>
                                        </div>
                                        <div className="left__row">
                                            <div className="row__title">
                                                Company Name:
                                            </div>
                                            <div className="row__value">
                                                {
                                                    !editAff ? 
                                                        viewAff?.display_name
                                                    :
                                                    (
                                                        <input type="text" placeholder="Company Name" defaultValue={viewAff?.display_name} onChange={(e)=>handleAffEdit(e.target.value, "display_name")}/>
                                                    )
                                                }
                                            </div>
                                        </div>
                                        <div className="left__row">
                                            <div className="row__title">
                                                Affiliate Code:
                                            </div>
                                            <div className="row__value">
                                                {viewAff?.code}
                                            </div>
                                        </div>
                                        <div className="left__row">
                                            <div className="row__title">
                                                Contact Name:
                                            </div>
                                            <div className="row__value">
                                                {
                                                    !editAff ? 
                                                        viewAff?.contact_name
                                                    :
                                                    (
                                                        <input 
                                                            type="text" 
                                                            defaultValue={viewAff?.contact_name}
                                                            placeholder="Contact Name"
                                                            onChange={(e)=>handleAffEdit(e.target.value, "contact_name")}
                                                        />
                                                    )
                                                }
                                            </div>
                                        </div>
                                        <div className="left__row">
                                            <div className="row__title">
                                                Notification Email:
                                            </div>
                                            <div className="row__value">
                                                {
                                                    !editAff ? 
                                                        viewAff?.email.notifications
                                                    :
                                                    (
                                                        <input
                                                            type="text"
                                                            placeholder="Notification Email Address"
                                                            defaultValue={viewAff.email.notifications}
                                                            onChange={(e)=>handleAffEdit(e.target.value, "email.notifications")}
                                                        />
                                                    )
                                                }
                                            </div>
                                        </div>
                                        <div className="left__row">
                                            <div className="row__title">
                                                Owner Email:
                                            </div>
                                            <div className="row__value">
                                                {
                                                    !editAff ?
                                                        viewAff?.email.owner
                                                    :
                                                    (
                                                        <input
                                                            type="text"
                                                            defaultValue={viewAff?.email.owner}
                                                            placeholder="Owner Email"
                                                            onChange={(e)=>handleAffEdit(e.target.value, "email.owner")}
                                                        />
                                                    )
                                                }
                                            </div>
                                        </div>
                                        <div className="left__row">
                                            <div className="row__title">
                                                Rate:
                                            </div>
                                            <div className="row__value">
                                                {(viewAff?.fee_rate*100).toFixed(2)}%
                                            </div>
                                        </div>
                                        <div className="left__row">
                                            <div className="row__title">
                                                Paypal Id:
                                            </div>
                                            <div className="row__value">
                                                {
                                                    !editAff ? 
                                                        viewAff?.paypal_id
                                                    :
                                                    (
                                                        <input 
                                                            type="text"
                                                            defaultValue={viewAff?.paypal_id}
                                                            placeholder="Paypal Id"
                                                            onChange={(e)=>handleAffEdit(e.target.value, "paypal_id")}
                                                        />
                                                    )
                                                }
                                            </div>
                                        </div>
                                        <div className="left__row">
                                            <div className="row__title">
                                                Current Affiliate Status:
                                            </div>
                                            <div className="row__value">
                                                {capitalize(viewAff.status)}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="view__right">
                                        {
                                            !editAff ? 
                                            (
                                                <div className="button edit" onClick={()=>setEditAff(true)}>Edit</div>
                                            ):
                                            (
                                                <>
                                                    <div className="button save" onClick={()=>handleAffSave()}>Save</div>
                                                    <div className="button cancel" onClick={()=>setEditAff(false)}>Cancel</div>
                                                </>
                                            )
                                        }
                                        <div className="decision__buttons">
                                            {
                                                (viewAff?.status === "pending" || viewAff.status === "denied") && 
                                                (
                                                    <div 
                                                        className="button approve" 
                                                        onClick={()=>setShowApprovalDialogue(true)}
                                                    >
                                                        Approve
                                                    </div>
                                                )
                                            }
                                            {
                                                (viewAff?.status === "pending" || viewAff?.status === "approved") &&
                                                (
                                                    <div className="button deny" onClick={()=>handleDenial()}>Deny</div>
                                                )
                                            }
                                        </div>
                                        {
                                            viewAff?.status === "approved" &&
                                            (
                                                <div 
                                                    className="button reconcile" 
                                                    onClick={()=>handleReconcile()}
                                                >
                                                    Reconcile
                                                </div>
                                            )
                                        }
                                        <div
                                            className="button send_message" 
                                            onClick={()=>handleSendMessage()}
                                        >
                                            Send Message
                                        </div>
                                    </div>
                                </div>
                            </>
                        )
                    }
                </div>
            </div>
            {
                showApprovalDialogue && 
                (
                    <div className="approval__dialogue">    
                        <h2>Approve Affiliate?</h2>
                        <p>Please enter a code for the affiliate</p>
                        <input 
                            type="text" 
                            placeholder="example: hustle_buddies" 
                            onChange={(e)=>handleCodeChange(e.target.value)}
                            value={approvalCode}
                        />
                        <p>Please enter the rate to pay this affiliate below:</p>
                        <input 
                            type="number" 
                            onChange={(e)=>setApprovalPercent(e.target.value)}
                            value={approvalPercent} 
                        />
                        <div className="button__group">
                            {
                                !disableApprovalSubmit ?
                                (
                                    <div className="button submit" onClick={()=>handleApproval()}>Submit</div>
                                ):
                                (
                                    <div className="button submit disabled">Submit</div>
                                )
                            }
                            
                            <div classname="button cancel" onClick={()=>setShowApprovalDialogue(false)}>Cancel</div>
                        </div>
                    </div>
                )
            }
            {
                showSendMessage && 
                (
                    <div className="manageAffiliates__sendMessage">
                        <div className="sendMessage__left">
                            <h3>Send Message</h3>
                            <button onClick={()=>handleSentMessage()}>Send</button>
                            <button onClick={()=>handleCancelMessage()}>Cancel</button>
                        </div>
                        <div className="sendMessage__right">
                            <input type="text" onChange={(e)=>setSubject(e.target.value)} value={subject} placeholder="Subject"/>
                            <textarea onChange={(e)=>setMessage(e.target.value)} value={message} placeholder="Message"></textarea>
                        </div>
                    </div>
                )
            }
        </>
    )
}

export default ManageAffiliates
