import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

export const firebaseConfig = {
    apiKey: "AIzaSyBiHYlsXGhqySCSRPdVcRHHnxZPq7tLk9A",
    authDomain: "my-upc-finder.firebaseapp.com",
    databaseURL: "https://my-upc-finder.firebaseio.com",
    projectId: "my-upc-finder",
    storageBucket: "my-upc-finder.appspot.com",
    messagingSenderId: "941380808770",
    appId: "1:941380808770:web:7fe7aae77b1582e4647ddb",
    measurementId: "G-0N86DZ9KR3"
  };

  firebase.initializeApp(firebaseConfig);

  export const db = firebase.firestore();
  export const auth = firebase.auth();