export const MONTH = {
    1: {
        SHORTNAME: 'Jan',
        LONGNAME: 'January',
        DISPLAYNUM: '01',
    },
    2: {
        SHORTNAME: 'Feb',
        LONGNAME: 'February',
        DISPLAYNUM: '02',
    },
    3: {
        SHORTNAME: 'Mar',
        LONGNAME: 'March',
        DISPLAYNUM: '03',
    },
    4: {
        SHORTNAME: 'Apr',
        LONGNAME: 'April',
        DISPLAYNUM: '04',
    },
    5: {
        SHORTNAME: 'May',
        LONGNAME: 'May',
        DISPLAYNUM: '05',
    },
    6: {
        SHORTNAME: 'Jun',
        LONGNAME: 'June',
        DISPLAYNUM: '06',
    },
    7: {
        SHORTNAME: 'Jul',
        LONGNAME: 'July',
        DISPLAYNUM: '07',
    },
    8: {
        SHORTNAME: 'Aug',
        LONGNAME: 'August',
        DISPLAYNUM: '08',
    },
    9: {
        SHORTNAME: 'Sep',
        LONGNAME: 'September',
        DISPLAYNUM: '09',
    },
    10: {
        SHORTNAME: 'Oct',
        LONGNAME: 'October',
        DISPLAYNUM: '10',
    },
    11: {
        SHORTNAME: 'Nov',
        LONGNAME: 'November',
        DISPLAYNUM: '11',
    },
    12: {
        SHORTNAME: 'Dec',
        LONGNAME: 'December',
        DISPLAYNUM: '12',
    }
}

export const PRODUCT_IDS = {
    PROD: {
        REG_LICENSE: 'prod_IcoiZgx7zJUT9B',
        ADD_LICENSE: 'prod_Ko2mrYfPiudbwx',
    },
    DEV: {
        REG_LICENSE: 'prod_IfSyLxE6Kc52yc',
        ADD_LICENSE: 'prod_KlqjrSUVD4oxCf',
    }
}

export const LICENSE_TYPE = {
    REGULAR: 'REG_LICENSE',
    ADDITIONAL: 'ADD_LICENSE'
}

export const ENV_TYPE = {
    PROD: 'PROD',
    DEV: 'DEV',
}