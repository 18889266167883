import React, {useState, useEffect} from 'react'
import { fetchFromAPI } from '../helpers';
import './ContactUs.css';
function ContactUs({user}) {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [errors, setErrors] = useState({name: false, email: false, message: true});
    const [disableSubmit, setDisableSubmit] = useState(true);
    const [successMessage, setSuccessMessage] = useState(false);

    useEffect(()=>{
        if(user){
            setName(user.displayName);
            setEmail(user.email);
        }
    },[user])

    useEffect(()=>{
        let errorFlag = false;
        Object.keys(errors).forEach((key, index) => {
            if(errors[key]){
                errorFlag = true;
            }
        });
        setDisableSubmit(errorFlag);
    }, [errors])

    const checkName = (value) => {
        setName(value);
        let test = /[a-zA-z]{3,}/g.test(value);
        if(!test){
            if(!errors.name){
                setErrors({...errors, name: true})
            }
        }else{
            if(errors.name){
                setErrors({...errors, name: false})
            }
        }
    }

    const checkEmail = (value) => {
        setEmail(value);
        let test = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g.test(value);
        if(!test){
            if(!errors.email){
                setErrors({...errors, email: true})
            }
        }else{
            if(errors.email){
                setErrors({...errors, email: false})
            }
        }
    }

    const checkMessage = (value) => {
        setMessage(value);
        let test = /^.{10,}$/g.test(value);
        if(!test){
            if(!errors.message){
                setErrors({...errors,message: true})
            }
        }else{
            if(errors.message){
                setErrors({...errors, message: false});
            }
        }
    }

    const handleSubmit = async(e)=>{
        e.preventDefault();
        //TODO: Write this code to send to the server with form response.
        const request = {
            name: name,
            email: email,
            message: message
        };

        const sentRequest = await fetchFromAPI('contact', {
            body: request,
        });

        if(sentRequest){
            clearForm();
            setSuccessMessage(true);
        }else{
            console.log('request not sent successfully');
        }
    }

    const clearForm = () => {
        setName("");
        setEmail("");
        setMessage("");
    }

    return (
        <div className="contactUs">
            <h1>Contact Us</h1>
            { !successMessage && 
                (
                    <div className="contact__form">
                        <div className="form__row">
                            <input 
                                type="text" 
                                placeholder="Name" 
                                value={name} 
                                onChange={(e)=>checkName(e.target.value)} 
                                className={errors.name ? "error" : ""}
                            />
                            <input 
                                type="text" 
                                placeholder="Email" 
                                value ={email} 
                                onChange={(e)=>checkEmail(e.target.value)} 
                                className={errors.email ? "error" : ""}
                            />
                        </div>
                        <div className="form__row">
                            <textarea value={message} onChange={(e)=>checkMessage(e.target.value)} placeholder="How Can We Help You?"></textarea>
                        </div>
                        <button 
                            disabled={disableSubmit} 
                            className={disableSubmit ? "disable" : ""} 
                            onClick={e=>handleSubmit(e)}
                        >
                            Submit
                        </button>
                    </div>
                )
            }
            {
                successMessage && 
                (
                    <div className="form__success">
                        <h2>Message Sent Successfully!</h2>
                        <p>
                            Your inquiry was sent successfully.  We will reach out to you via email as soon as possible.  Thank you for using My UPC Finder and we hope you are having a great experience!
                        </p>
                        <p>
                            If you have questions on how to use My UPC Finder and you have not already seen our video tutorials, please visit <a href="https://myupcfinder.com/support.html">https://myupcfinder.com/support.html</a> for more information on how to use this tool.
                        </p>
                    </div>
                )
            }
        </div>
    )
}

export default ContactUs
