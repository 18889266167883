import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useUser } from 'reactfire';
import { db } from '../firebase';
import { settingNames } from '../enumeration';
import './UserHome.css';
import { Link } from 'react-router-dom';
import { useQuery } from '../helpers';

function UserHome({ settings, setUserSettings }) {
    const user = useUser();
    const [settingsChecklist, setSettingsChecklist] = useState(false);
    const query = useQuery(useLocation());

    const setReferrer = async (referralCode) => {
        await db.collection('users').doc(user.uid).set({referId: referralCode}, {merge: true});
        const settings = (await db.collection('users').doc(user.uid).get()).data();
        console.log('setting referrer based on query parameter');
        settings && setUserSettings(settings);
    }

    const checkAffiliate = async () => {
        const referrers = (await db.collection('affiliates').get()).docs.map(doc => doc.data());
        const isReferrer = referrers.filter(refer => refer.code === query.get('am_referrer')).length > 0;
        isReferrer && setReferrer(query.get('am_referrer'));
    }

    (query.get('am_referrer') && settings?.referId.length === 0) && checkAffiliate();

    const runChecklist = async () => {
        let checklist = {
            firstName: false,
            lastName: false,
            email: false,
            stripeCustomer: false,
            activePlan: false,
            completed: 0,
            incomplete: 0,
            incompleteItems: [],
            completeItems: []
        };
        console.log('user settings: ', settings);
        settings?.activePlan?.id.length > 0 ? checklist.activePlan = true : checklist.activePlan = false;
        settings?.firstName.length > 0 ? checklist.firstName = true : checklist.firstName = false;
        settings?.lastName.length > 0 ? checklist.lastName = true : checklist.lastName = false;
        settings?.email.length > 0 ? checklist.email = true : checklist.email = false;
        settings?.stripeCustomerId.length > 0 ? checklist.stripeCustomer = true : checklist.stripeCustomer = false;
        await Object.keys(checklist).forEach((item, index) => {
            if(item !== "completed" && item !== "incomplete" && item !== "incompleteItems"){
                !checklist[item] ? checklist.incomplete++ : checklist.completed++;
                !checklist[item] ? checklist.incompleteItems.push(item) : checklist.completeItems.push(item);
            }
        });
        setSettingsChecklist(checklist);
    }
    ( settings?.email && !settingsChecklist) && runChecklist();

    const renderProgressBar = () =>{
        let bars = [];
        let percentComplete = (settingsChecklist.completed / (settingsChecklist.completed + settingsChecklist.incomplete)).toFixed(4)
        for(let i = 0; i < settingsChecklist.completed; i++){
            if(i === settingsChecklist.completed - 1){
            bars.push(<div className="bars__complete" key={`progressBars_${i}`}>{(percentComplete*100).toFixed(2)}%</div>)
            }else{
                bars.push(<div className="bars__complete" key={`progressBars_${i}`}></div>);
            }
            
        }
        for(let i = 0; i < settingsChecklist.incomplete; i++){
            bars.push(<div className="bars__incomplete" key={`progressBarsIncomplete_${i}`}></div>);
        }
        return bars
    }

    return (
        <div className="userHome">
            <h1>Welcome {user?.displayName}!</h1>
            <div className="userHome__extensionInstallReminder">
                <div>
                    <h2>Have You Installed the Extension Yet?</h2>
                    <p>
                        If you haven't installed the My UPC Finder Extension yet from the Chrome Web Store, 
                        go ahead and do that now that you are logged in. From first login on this website, 
                        we give you 7 days to try it for free!  Once your free trial is up, log in here and 
                        navigate to the Subscriptions page where you can choose how you would like to 
                        subscribe to My UPC Finder!
                    </p>
                    <a 
                        href="https://chrome.google.com/webstore/detail/my-upc-finder-basics/pgojhmlolgdcepdhcpfkolbajfdjcali" 
                        target="_blank" 
                        className="button"
                    >
                        Click Here
                    </a>
                </div>
                <div className="extensionInstallReminder__image">
                    <img src="./images/home_view.png" alt="Home View of Extensions" />
                </div>
            </div>
        </div>
    )
}

export default UserHome
