import React, {useState, useEffect} from 'react'
import './AffiliateReconciliation.css';
import { useParams } from 'react-router-dom'
import {db} from '../firebase';
import {fetchFromAPI, formatDate} from '../helpers';
import Loading from '../components/Loading';

function AffiliateReconciliation({user}) {
    const {id} = useParams();
    const [ loading, setLoading ] = useState(false);
    const [ transactionList, setTransactionList ] = useState([]);
    const [ reconcileList, setReconcileList ] = useState([]);
    const [ affiliateInfo, setAffiliateInfo ] = useState({});
    const [ reconcileCount, setReconcileCount ] = useState(0);
    const [ summaryStats, setSummaryStats ] = useState({
        totalPaid: 0,
        totalUnpaid: 0,
        paidLastMonth: 0,
    });

    useEffect(()=>{
        console.log('affiliate info >>> ', affiliateInfo);
    },[affiliateInfo])

    useEffect(()=>{
        getTransactionList();
    },[id])

    useEffect(()=>{
        setLoading(true);
        let summary = {
            totalPaid: 0,
            totalUnpaid: 0,
            paidLastMonth: 0
        }
        const currentMonth = (new Date()).getMonth() + 1;
        const currentYear = (new Date()).getFullYear();
        const aff_fee = (affiliateInfo.fee_rate === 0 || affiliateInfo.fee_rate === undefined) ? 0 : affiliateInfo.fee_rate

        transactionList.forEach((t, tIndex) => {
            if(t.payment?.paid){
                summary.totalPaid += t.payment?.paid_amount;
            }else{
                summary.totalUnpaid += t.amount*aff_fee
            }
            
            if(((new Date(t.payment?.paid_date)).getMonth() + 1 === (currentMonth-1)%12)&&(new Date(t.payment?.paid_date).getFullYear() === (currentMonth-1)%12 === 12 ? currentYear-1 : currentYear)){
               summary.paidLastMonth += t.payment?.paid_amount;
            }
        })

        console.log('summary >> ', (summary.totalUnpaid/100).toFixed(2));
        setSummaryStats(summary);
        setLoading(false);
    },[transactionList, affiliateInfo])

    const getTransactionList = async () => {
        const transactions = await db.collection('transactions').where('referral','==',id).get();
        const aff = await db.collection('affiliates').where('code','==',id).get();
        let list = transactions.docs.map(transaction => {
            const transDoc = transaction.data();
            const transId = transaction.id;
            return {transId, ...transDoc}
        });
        list.sort((a,b)=>b.date-a.date);
        setTransactionList(list); 
        setReconcileList(list.map(l => false));
        if(aff.docs.length > 0){
            setAffiliateInfo(aff.docs[0].data())
        }
    }

    const translateDuration = (timeElapsed) => {
        let days = Math.floor((timeElapsed/1000)/86400);
        if(Math.floor(days/28) === 1){
            return "Monthly"
        }else if(Math.floor(days/28) === 3){
            return "Quarterly"
        }else if(Math.floor(days/28) === 6){
            return "Bi-Yearly"
        }else{
            return "Yearly"
        }
    }

    const handleCheck = async (event, index) => {
        setLoading(true);
        let list = reconcileList;
        list[index] = event.target.checked;
        setReconcileList(list);
        if(event.target.checked){
            setReconcileCount(reconcileCount+1)
        }else{
            setReconcileCount(reconcileCount-1)
        }
        setLoading(false);
    }

    const handleSave = async () => {
        let recList = [];
        reconcileList.forEach((rl,rlIndex) => {
            if(rl === true){
                let tlUpdate = transactionList[rlIndex];
                let aff_fee = !affiliateInfo.fee_rate ? 0 : affiliateInfo.fee_rate;
                if(!tlUpdate.payment){
                    tlUpdate.payment = {
                        paid: true,
                        paid_date: new Date().getTime(),
                        paid_amount: tlUpdate.amount * aff_fee
                    }
                }else{
                    tlUpdate.payment.paid_amount = tlUpdate.amount * aff_fee;
                }
                recList.push(transactionList[rlIndex]);
            }
        });
        let result = await fetchFromAPI('affiliates/reconcile',{
            method: 'POST',
            body: recList
        });
        if(result){
            setLoading(true);
            setReconcileCount(0);
            await getTransactionList();
            setReconcileList(transactionList.map(tl=>false));
            setLoading(false);
        }
    }

    const handleCancel = () => {
        setLoading(true);
        setReconcileList(transactionList.map(tl=>false));
        setReconcileCount(0);
        setLoading(false);
    }

    return !loading ? (
        <div className="affiliateReconciliation">
            <h1>Reconcile Transactions</h1>
            <div className="affiliateReconciliation__table">
                <div className="table__header">
                    <div className="header__value"></div>
                    <div className="header__value">Date</div>
                    <div className="header__value">Sub Amount</div>
                    <div className="header__value">Commission</div>
                    <div className="header__value">Paid</div>
                    <div className="header__value">Paid Date</div>
                    <div className="header__value">Code</div>
                    <div className="header__value">Duration</div>
                </div>
                <div className="table__body">
                    {
                        transactionList?.map((transaction, transactionIndex) => {
                            return(
                                <div className="body__row" key={`transaction_reconciliation_${transactionIndex}`}>
                                    <div className="row__value">
                                        <input 
                                            type="checkbox" 
                                            disabled={transaction.payment?.paid ? true : false}
                                            onChange={(e)=>handleCheck(e, transactionIndex)}
                                            checked={!reconcileList[transactionIndex] ? false : true}
                                        />
                                    </div>
                                    <div className="row__value">{formatDate(transaction.date,"MM-DD-YYYY")}</div>
                                    <div className="row__value">${(transaction.amount/100).toFixed(2)}</div>
                                    <div className="row__value">${((transaction.amount*affiliateInfo?.fee_rate)/100).toFixed(2)}</div>
                                    <div className="row__value">{transaction.payment?.paid ? "Yes" : "No"}</div>
                                    <div className="row__value">
                                        {transaction.payment?.paid_date > 0 ? formatDate(transaction.payment?.paid_date,"MM-DD-YYYY") : ""}
                                    </div>
                                    <div className="row__value">{transaction.referral}</div>
                                    <div className="row__value">{translateDuration(transaction.end - transaction.start)}</div>
                                </div>
                            )
                        })
                    }
                </div>
                {
                    
                    <div className={`table__buttonGroup ${reconcileCount > 0 ? "show" : "hide"}`}>
                        <button className="button submit" onClick={(e)=>handleSave()}>Submit</button>
                        <button className="button cancel" onClick={()=>handleCancel()}>Cancel</button>
                    </div>
                    
                }
            </div>
            <div className="affiliateReconciliation__summary">
                <h2>Summary</h2>
                <div className="summary__container">
                    <div className="summary__box">
                        <h3>Total Paid</h3>
                        <div className="box__value">${(summaryStats?.totalPaid/100).toFixed(2)}</div>
                    </div>
                    <div className="summary__box">
                        <h3>Total Unpaid</h3>
                        <div className="box__value">${(summaryStats?.totalUnpaid/100).toFixed(2)}</div>
                    </div>
                    <div className="summary__box">
                        <h3>Paid Last Month</h3>
                        <div className="box__value">${(summaryStats?.paidLastMonth/100).toFixed(2)}</div>
                    </div>
                </div>
            </div>
        </div>
    ) : (
        <div><Loading /></div>
    )
}

export default AffiliateReconciliation
