import React from 'react'

function CheckoutSuccess() {
    const url = window.location.href;
    const sessionId = new URL(url).searchParams.get('session_id');
    return (
        <div className="checkout__success">
            <h3>Checkout was a Success! {sessionId} </h3>
        </div>
    )
}

export default CheckoutSuccess
